import React from 'react'
import { Card, Icon, Image,   Grid, Header } from 'semantic-ui-react'

const Settings = () => (
  <Grid textAlign='center' style={{ height: '50vh' }} verticalAlign='middle'>
  <Grid.Column style={{ maxWidth: 450 }}>
    <Header as='h2' color='teal' textAlign='center'>
Personal Settings
     
    </Header>
  <Card>
    <Image src='https://react.semantic-ui.com/images/avatar/large/daniel.jpg' wrapped ui={false} />
    <Card.Content>
      <Card.Header>Daniel</Card.Header>
      <Card.Meta>Joined in 2016</Card.Meta>
      <Card.Description>
        Daniel is a comedian living in Nashville.
      </Card.Description>
    </Card.Content>
    <Card.Content extra>
      
        <Icon name='user' />
        10 Friends
      
    </Card.Content>
  </Card>
  </Grid.Column>
    </Grid>
)

export default Settings
