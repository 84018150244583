import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import profileReducer from "./profileReducer";
import customerReducer from "./customerReducer";
import memberReducer from "./memberReducer";
 
import { reducer as reduxForm } from 'redux-form';

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  customer: customerReducer,
  member: memberReducer,
  form: reduxForm
});
