import React, { Component } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
 
import { connect } from "react-redux";
import { getCurrentProfile } from "../../actions/profileActions";
 
import { Menu, Divider } from 'semantic-ui-react'
import { logoutUser } from "../../actions/authActions";
import { clearCurrentProfile } from "../../actions/profileActions";


class Sidebar extends Component {
  componentDidMount() {
    this.props.getCurrentProfile();
  
  }
  state = { activeItem: 'inbox' }
  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();
  }

  handleItemClick = (e, { name }) => {
  //  console.log("handleItemClick",name)
    this.setState({ activeItem: name }) }
  render() {
    const { isAuthenticated, user } = this.props.auth;
    const { activeItem } = this.state
 
    const authLinks = (
      <Fragment>
        <ul
          className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          <a
            className="sidebar-brand d-flex align-items-center justify-content-center"
            href="/dashboard" style={{textTransform:"capitalize"}}
          >
            <img
              src="/img/contentether_01.png"
              height="40"
              width="40"
              alt="dashboard placeholder"
            />ContentEther
          </a>
     <Divider hidden/>
      <Menu vertical>
      <Menu.Item>
          <Menu.Header>Dashboard</Menu.Header>
          <Menu.Menu>
            <Menu.Item  as={ Link } to="/dashboard"
              name='Overview'
              active={activeItem === 'Overview'}
              onClick={this.handleItemClick}
            />
            
              <Menu.Item as={ Link } to="/video_player_demo" 
              name='video_player_demo'
              active={activeItem === 'video_player_demo'}
              onClick={this.handleItemClick}
            />
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Admin</Menu.Header>
          <Menu.Menu>
            {/** <Menu.Item
              name='users' as={ Link } to="/users"
              active={activeItem === 'users'}
              onClick={this.handleItemClick}
            /> 
            */}
            <Menu.Item
              name='customers'  as={ Link } to="/customers"
              active={activeItem === 'customers'}
              onClick={this.handleItemClick}
            />
          <Menu.Item
              name='members' as={ Link } to="/members"
              active={activeItem === 'members'}
              onClick={this.handleItemClick}
            />
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Send</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name='From Customer to User'  as={ Link } to="/customertouser" 
              active={activeItem === 'From Customer to User'}
              onClick={this.handleItemClick}
            />
            <Menu.Item
              name='From User to User' as={ Link } to="/usertouser" 
              active={activeItem === 'From User to User'}
              onClick={this.handleItemClick}
            />
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Documentation</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name='API Document'  as={ Link } to="/apidoc" 
              active={activeItem === 'API Document'}
              onClick={this.handleItemClick}
            />
           
          </Menu.Menu>
        </Menu.Item>
        

        <Menu.Item>
          <Menu.Header>Settings</Menu.Header>

          <Menu.Menu>
            <Menu.Item name='Profile'  as={ Link } to="/edit-profile" active={activeItem === 'Profile'} onClick={this.handleItemClick}>
            Profile
            </Menu.Item>
 
            <Menu.Item name='Logout' active={activeItem === 'Logout'} onClick={this.onLogoutClick.bind(this)}>
            Logout
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      </Menu>
          

       
  
         
        </ul>
      </Fragment>
    );
    const guestLinks = null;

    return <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>;
  }
}
Sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
 
};

const mapStateToProps = state => ({
  profile: state.profile,
 
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getCurrentProfile,  logoutUser, clearCurrentProfile }
)(Sidebar);
