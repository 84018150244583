import React from "react";

export default function CustomerActivationSuccess(props) {
  const {
    name,
    companyname,
    email,
    packagetype,
    payment
  } = props.location.state.detail;
  const packageTypes = ["Starter ", "Professional", "Enterprise"];
  // Select options for Payment Method
  const paymentMethods = ["Purchase Order ", "Corporate Creditcard"];

  console.log(props.location.state.detail);
  return (
    <div className="register">
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <h1 className="display-4 text-center">Activation Successful!</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8 m-auto">
            <table className="table">
              <tbody>
                <tr>
                  <th scope="row">Customer Name</th>
                  <td>{name}</td>
                </tr>
                <tr>
                  <th scope="row">Company Name</th>
                  <td>{companyname}</td>
                </tr>
                <tr>
                  <th scope="row">Email</th>
                  <td>{email}</td>
                </tr>
                <tr>
                  <th scope="row">Package Selected</th>
                  <td>{packageTypes[packagetype - 1]}</td>
                </tr>
                <tr>
                  <th scope="row">Payment Method</th>
                  <td>{paymentMethods[payment - 1]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
