import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import MainContainer from '../../pages/MainContainer';
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <MainContainer>
        <Grid className="fixed-footer">
          <Grid.Column width={8}>
            <h1>Place Footer items here</h1>
             
          </Grid.Column>
          <Grid.Column width={8}>
            
          </Grid.Column>
          <Grid.Row columns={1}>
            <Grid.Column>
              © 2019 ContentEther. All rights reserved
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </MainContainer>
    );
  }
}

export default Footer;
