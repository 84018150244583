import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import TextFieldGroup from "../common/TextFieldGroup";
 
import { getMembers,transferToMember } from "../../actions/memberActions";
import {  Form, Grid, Header,Button,  Segment,Message } from 'semantic-ui-react'
import SelectListGroup from "../common/SelectListGroup";

class FromCustomerToUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
     customer:"",
     network:"",
     amount:"1",
     memberSelected:"",
     visible: false ,
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const members=this.props.getMembers()
    console.log("componentDidMount ",members)
   
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const { members } = nextProps.member;
    console.log("nextProps", members);
  
    this.setState({ members });


    if(members) {      
      // let memberList= {  key: 'm1', label: "* Select Member", value: null };
      let memberList=  members.map((member,idx)=>( {  key: idx, label: member.username, value:member.username }))
      memberList.unshift({  key: 'm1', label: "* Select Member", value: null })
   
    console.log(memberList)
    this.setState({memberList})
  }
    
  }

  onSubmit(e) {
    e.preventDefault();

    const sendTokenData = {
      customer_username: this.state.customer,
      network: this.state.network,
      username: this.state.memberSelected,
      CEToken: this.state.amount ,
      amount:this.state.amount
      
     
    };
    console.log("sendTokenData=",sendTokenData)

    this.props.transferToMember(sendTokenData)
    this.setState({visible:true,amount:0,memberSelected:"", customer:"",network:""})
//this.props.createProfile(sendTokenData, this.props.history);
  }

  onChange(e) {

    this.setState({ [e.target.name]: e.target.value },()=>("onchange ",this.state));
  }

  handleDismiss = () => {
    this.setState({ visible: false })

    setTimeout(() => {
      this.setState({ visible: true })
    }, 2000)
  }
  
  render() {
    const { errors,memberList } = this.state;
    const options = [
      { key: 'm1', label: "* Select Customer", value: null },
      { key: 'm2',label: "Videogram", value:  "Videogram" },

    ];
    // Select options for Payment Method
    const options2 = [
      {  key: 'm1', label: "* Select Network Type", value: null },
      {  key: 'm3', label: "Rinkeby Test Network", value: "Rinkeby Test Network" },
      {  key: 'm2', label: "Main Ethereum Network", value: "Main Ethereum Network" },
      {  key: 'm4', label: "Private Ethereum Network", value: "Private Ethereum Network" }
    ];
    const { visible} =this.state
    return (
      <>
       {   visible  &&<Message positive      onDismiss={this.handleDismiss}>
          <Message.Header>CEToken(s) Sent</Message.Header>
          <p>
            Do you want to send CEToken(s) again?
          </p>
        </Message>}
      <Grid textAlign='center' style={{ height: '50vh',colornp:"#6435C9" }} verticalAlign='middle'>
        
   
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2'  style={{ color:"#6435C9" }}   textAlign='center'>
       Send CEToken from Customer to User
        
        </Header>
        <Form size='large' noValidate onSubmit={this.onSubmit}>
          <Segment stacked>
              <SelectListGroup
                  placeholder="Customer"
                  info="Select Customer"
                  name="customer"
                  value={this.state.customer}
                  onChange={this.onChange}
                  options={options}
                  error={errors.network}
                />
                 <SelectListGroup
                  placeholder="Network"
                  info="Network Type"
                  name="network"
                  value={this.state.network}
                  onChange={this.onChange}
                  options={options2}
                  error={errors.network}
                />     
                {   memberList &&
                <SelectListGroup
                  placeholder="User"
                  name="memberSelected"
                  info="Transfer TO User"
                  value={this.state.memberSelected}
                  onChange={this.onChange}
                  options={this.state.memberList}
                  error={errors.memberSelected}
                />   
                }
                <TextFieldGroup
                  placeholder="CE Token Amount "
                  name="amount"
                  info="CEToken amount"
                  value={this.state.amount}
                  onChange={this.onChange}
                  error={errors.amount}
                 
                />

  
  
            <Button style={{ backgroundColor:"#6435C9",color:"white" }}  fluid size='large' type="submit" >
              Submit
            </Button>
          </Segment>
        </Form>
        
      </Grid.Column>
    </Grid>
    </>
    );
  }
}

FromCustomerToUser.propTypes = {
  getMembers: PropTypes.func.isRequired,
  transferToMember: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  member:state.member,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getMembers,transferToMember }
)(withRouter(FromCustomerToUser));
