import axios from "axios";

import {
  GET_CUSTOMERS,
  CUSTOMER_LOADING,
  GET_ERRORS,
  SET_CURRENT_USER,
  TRANSFER_TO_CUSTOMER
}
  from "./types";

// Get all customers
export const getCustomers = () => dispatch => {
  dispatch(setCustomerLoading());
  axios
    .get("/api/customers")
    .then(res =>
      dispatch({
        type: GET_CUSTOMERS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CUSTOMERS,
        payload: {}
      })
    );
};


// Add token to  customer
export const transferToCustomer = (data) => dispatch => {
  dispatch(setCustomerLoading());
  console.log(data)
  axios
    .post("/api/customers/transfertocustomer",data)
    .then(res =>{
      
      this.getCustomerBalance(data)   

      dispatch({
        type: TRANSFER_TO_CUSTOMER,
        payload: {}
      })
    }

    )
    .catch(err =>
      dispatch({
        type: TRANSFER_TO_CUSTOMER,
        payload: {}
      })
    );
};

// Profile loading
export const setCustomerLoading = () => {
  return {
    type: CUSTOMER_LOADING
  };
};

 



// get Customer Balance
export const getCustomerBalance = (customer) => dispatch => {
 
  console.log(customer)
  const data ={
    id:customer._id,
    "type": "customer",
  "name": customer.name,
  "username": customer.username,
  "api_version":"1"}


  axios
    .post("/api/customers/getcustomerbalance",data)
    .then(res =>
      
      dispatch({
        type: TRANSFER_TO_CUSTOMER,
        payload: {}
      })
      

    )
    .catch(err =>
      dispatch({
        type: TRANSFER_TO_CUSTOMER,
        payload: {}
      })
    );
};