import React, { Component } from "react";
import VideoPlayer from "react-video-js-player";
import PropTypes from 'prop-types';
import formatCurrency from 'format-currency'
import { connect } from 'react-redux';
import {   Grid, Header, Image, Icon,Message,    Segment,Card,Rating,Button } from 'semantic-ui-react'


import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {  getMemberBalance,redeemUserToCustomer } from "../../actions/memberActions";

class VideoApp extends Component {
 
  player = {};
  state = {
    hasToken:this.props.auth.user.cetoken?true:false,
    member:{},
    cetoken:0,
     user:this.props.auth.user,
    video: {
      src:
        "https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_20mb.mp4",
      poster: "/img/bigbuckbunny.jpg",
      open: false 
    },
    visible: false,
    purchased:false,
    debug:[  ],
    hideControls:['play','volume','seekbar','timer','playbackrates','fullscreen']
  };
  componentDidMount() {
     
 
    console.log("componentDidMount",this.state.user );
    const {email}=this.state.user 
    const dataItem={
      "type": "user",
      "username":email ,
      "customer_username":"Videogram",
      "api_version":"1"
    }
    

   const member= this.props.getMemberBalance(dataItem)
   this.setState({member})
    
  }
  componentWillReceiveProps(nextProps) {
    //console.log("nextProps member", nextProps.member);
  if (nextProps.errors) {
    this.setState({ errors: nextProps.errors });
  }
  const { member } = nextProps.member;
  const cetoken= (nextProps.member.member.credentials[ "Rinkeby Test Network"].CEToken *1000000000).toFixed(0);
  console.log("nextProps member", nextProps.member.member.credentials[ "Rinkeby Test Network"].CEToken);
  if(!this.state.purchased){
  this.setState({ member,cetoken });
  }else{
    this.setState({ member,cetoken:cetoken-1 });
  }

  }


  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  onPlayerReady(player) {
    console.log("Player is ready: ", player);
    this.player = player;
  }

  onVideoPlay(duration) {
    
    
    let {debug,purchased} =this.state
    if(!purchased) {
      this.player.pause()
      debug.push("Not Purchased! Stop Video at: "+ duration)
      this.setState({
        visible: !this.state.visible
    });
    }

    console.log("Video played at: ", duration);


    debug.push("Video played at: "+ duration)
    this.setState({debug})

  }

  onVideoPause(duration) {
    console.log("Video paused at: ", duration);
    let {debug} =this.state
    debug.push("Video paused at: "+ duration)
    this.setState({debug})
  }

  onVideoTimeUpdate(duration) {
    console.log("Time updated: ", duration);
  }

  onVideoSeeking(duration) {
    console.log("Video seeking: ", duration);
    let {debug} =this.state
    debug.push("Video seeking  : "+ duration)
    this.setState({debug})
  }

  onVideoSeeked(from, to) {
    console.log(`Video seeked from ${from} to ${to}`);
    let {debug} =this.state
    debug.push(`Video seeked from ${from} to ${to}`)
    this.setState({debug})
  }

  onVideoEnd() {
    console.log("Video ended");
  }
  onButtonPlay() {
    console.log("Video onButtonPlay");
    this.player.play()
  }
  onButtonStop() {
    console.log("Video onButtonPlay");
    this.player.pause()
  }
  toggleDialog =() => {
    this.setState({
        visible: !this.state.visible
    });
  }
  purchaseMovie =() => {

    const {cetoken} = this.state
    console.log("purchaseMovie cetoken=",cetoken)
   if(cetoken>0){
     let newCEtoken=cetoken-1
    this.setState({
       purchased:true,cetoken:newCEtoken,hideControls:[]
    },()=>console.log("purchaseMovie cetoken=",this.state.cetoken,newCEtoken));
    this.player.hideControls=[]
    let {debug} =this.state
    debug.push(`Video PURCHASED for 1 CE Token, enable controls`)
    this.setState({debug})
    
    const {customer_username,name,type,username,network}= this.state.member

    const data={
        api_version: "1",
        type: "user",
        customer_username: "Videogram",
        username,
        CEToken: "0.000000001",
        network
    }

    this.props.redeemUserToCustomer(data)
    const {email}=this.state.user 
    const dataItem={
      "type": "user",
      "username":email ,
      "customer_username":"Videogram",
      "api_version":"1"
    }
    

   const member= this.props.getMemberBalance(dataItem)
   this.setState({member})

  } else{
    this.setState({
      hasToken: false})
  }
  this.setState({
    visible: !this.state.visible})
  }
  render() {
  //  console.log(this.props.auth.user)
    const {user}=this.props.auth
    const rev=this.state.debug.reverse()
    const debuglines=rev.map((obj,idx)=> { return  ( <div key={idx}> {obj}</div>)})
    const {purchased,cetoken,hideControls,member,hasToken}= this.state
   //   console.log(member)
     // const CEToken= (cetoken)? formatCurrency( member.credentials["Rinkeby Test Network"].CEToken*1000000000):0
     const CEToken= (cetoken)? (cetoken):0
 
  //  console.log(debuglines)
    return (<div>
         {this.state.visible && 
              <Dialog title={"Purchase Movie"} onClose={this.toggleDialog} width="720px">
                <Grid  >
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Image src='/img/Big_buck_bunny_poster_339px.jpg' />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <br/>
                    <Card>
                      <Card.Content>
                      
                        <Card.Header>Big Buck Bunny (2008) <br/> <Rating icon='star' defaultRating={4} maxRating={5}  size='mini'/></Card.Header>
                        <Card.Meta>TV-Y7 | 10min | Animation, Short, Comedy | 10 April 2008 (Netherlands)</Card.Meta>
                        <Card.Description><br/>
                       <b> Overview</b><br/> 
                       <p>Follow a day of the life of Big Buck Bunny when he meets three bullying rodents: Frank, Rinky, and Gamera. The rodents amuse themselves by harassing helpless creatures by throwing fruits, nuts and rocks at them. After the deaths of two of Bunny's favorite butterflies, and an offensive attack on Bunny himself, Bunny sets aside his gentle nature and orchestrates a complex plan for revenge.</p>
                        </Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                      <p>Director: <b>Sacha Goedegebure</b><br/>
                      Writers:  <b>Sacha Goedegebure</b> (original story),  <b>Sacha Goedegebure</b> (original script)</p>
                      </Card.Content>
                    </Card>
                    
                    </Grid.Column>
                </Grid.Row>
                </Grid>
            

                <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDialog}>Cancel</button>
                         
                        <Button  className="k-button" color='red' style={{background:"green",color:"white"}} onClick={this.purchaseMovie}>Buy Movie (1 CE token)</Button>
                </DialogActionsBar>
              </Dialog>
          }
    
      <Grid textAlign='center' style={{ height: '50vh' }} verticalAlign='middle'>
    
      <Grid.Column style={{ maxWidth: 780 }}>
        <Header as='h2' color='red' textAlign='center'>
       Pay-Per-View  Video Demo
        
        </Header>
          {hasToken &&
        <Message negative>
          <Message.Header>You don't have enought CEToken</Message.Header>
          <p>Purchase is not possible. </p>
        </Message>
          }
        
        <Segment  width="720" stacked>
        <VideoPlayer
          controls={true}
         
          src={this.state.video.src}
          poster={this.state.video.poster}
          width="720"
          height="420"
          onReady={this.onPlayerReady.bind(this)}
          onPlay={this.onVideoPlay.bind(this)}
          onPause={this.onVideoPause.bind(this)}
          onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
          onSeeking={this.onVideoSeeking.bind(this)}
          onSeeked={this.onVideoSeeked.bind(this)}
          onEnd={this.onVideoEnd.bind(this)}
        />
       
        { !purchased &&
        <div> 
          <br/>
        <Button color="violet" onClick={ this.toggleDialog }  >Purchase movie</Button>
        </div>
        }
        </Segment>
        <Segment  width="720" stacked>
            <p> User Name: <b> {user.name}</b></p>
            <p> Email Id : <b> {user.email}</b></p>
            <p> Customer : <b> Videogram</b></p>
            <p> CEToken: <b>{CEToken}</b></p>
            
          </Segment>
          <Segment style={{display:"none"}} width="720" stacked>
            <p>Debug:</p>
             {debuglines}
            
            
          </Segment>
        </Grid.Column>
    </Grid>
    </div>
    );
  }
}

VideoApp.propTypes = {
  getMemberBalance: PropTypes.func.isRequired,
  redeemUserToCustomer: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  member:state.member,
  errors: state.errors
});

export default connect(mapStateToProps, {  getMemberBalance,redeemUserToCustomer })(VideoApp);
