import React from "react";
import { } from "semantic-ui-react";
import {
  Button,
  Container,
   Grid,
  Header,
  Segment, Menu, Icon, Image, 
 } from 'semantic-ui-react'

export default () => {
  return (
    <Container fluid>
    <Grid     stackable>
      <Grid.Row>
        <Grid.Column width={10}>
        
        </Grid.Column>
        <Grid.Column width={6}>
          <p className="footer-note" style={{color:"#6435C9",right:"10px",position:"absolute"}}>
          Copyright &copy; {new Date().getFullYear()} ContentEther.com. All Rights
          Reserved.
        </p>
        </Grid.Column>
        
      </Grid.Row>
    </Grid>
  </Container>
  );
};
