
import React from 'react';
 import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
//import { customers } from '../../data/customers'
import DialogContainer from './DialogContainer';
import cellWithEditing from './cellWithEditing';

import { Header, Icon, Button } from 'semantic-ui-react'
import { getCustomers,transferToCustomer,getCustomerBalance } from "../../actions/customerActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CustomCell from "./CustomCell"
import { orderBy } from '@progress/kendo-data-query';

class Customers extends React.Component {
    state = {
        customer: [],
        productInEdit: undefined,
        sort: [
            { field: 'username', dir: 'asc' },
            { field: 'name', dir: 'asc' },
        ]
    };
    componentDidMount() {
     this.props.getCustomers()
     
      }
    
    componentWillReceiveProps(nextProps) {
       //   console.log("nextProps", nextProps);
        if (nextProps.errors) {
          this.setState({ errors: nextProps.errors });
        }
       
        const { customers } = nextProps.customer;
      
        this.setState({ customers });
      }
 

    remove = (dataItem) => {
        const customers = this.state.customers.slice();
        const index = customers.findIndex(p => p.ProductID === dataItem.ProductID);
        if (index !== -1) {
            customers.splice(index, 1);
            this.setState({
                customers: customers
            });
        }
    }
    refresh = (dataItem) => {
         
        this.props.getCustomerBalance(dataItem)
    }
    addToken = (dataItem) => {
       
        dataItem.amount=1
        dataItem.network="Rinkeby Test Network"
        const productInEdit=this.cloneProduct(dataItem)
        console.log("addToken,",productInEdit)
        this.setState({ productInEdit  });
       
    }

    save = () => {
        const dataItem = this.state.productInEdit;
      
        const customers = this.state.customers.slice();
        console.log("save dataItem=",dataItem)
        if( parseInt(dataItem.amount) > 0 ){
            console.log("TODO add amount to customer from Main",parseInt(dataItem.amount))
            console.log("data from input form ",dataItem)
            this.props.transferToCustomer(dataItem)
            this.props.getCustomerBalance(dataItem)

        }
      

        if (dataItem.name === undefined) {
            customers.unshift(this.newProduct(dataItem));
        } else {
            const index = customers.findIndex(p => p.name === dataItem.name);

            customers.splice(index, 1, dataItem);
        }

        this.setState({
            customers: customers,
            productInEdit: undefined
        });
        console.log("save",customers)

     

    }

    cancel = () => {
        this.setState({ productInEdit: undefined });
    }

    insert = () => {
        this.setState({ productInEdit: { credentials:{}} });
    }

      

    columns = [
        {
          title: 'Type',
          field: 'type',
          show: false
        },
        {
          title: 'Customer Name (username)',
          field: 'username',
          show: true
        },
        {
          title: 'Admin Email (name)',
          field: 'name',
          show: true
        },
        {
            title: 'Api Ver Name',
            field: 'api_version',
            show: false
          },
          {
            title: 'Status',
            field: 'status',
            show: false
          },
            
      ]
      MyCustomCell = (props) => <CustomCell {...props} myProp={this.customData} />
    render() {
       
      
        const {customers} =this.state
   
        const isEmpty=JSON.stringify(customers) === "{}" || typeof customers ==="undefined" ||  customers === null
         
        return (
            <>
                <Header as='h2' icon textAlign='center'>
                <Icon name='user' circular />
                <Header.Content>Customers</Header.Content>
                </Header>
                {!isEmpty &&
                <Grid
                    data={orderBy(customers, this.state.sort)}
                    
                    style={{ height: '920px' }}
                        sortable
                        sort={this.state.sort}
                        onSortChange={(e) => {
                          this.setState({
                          sort: e.sort
                        });
                      }}
                    
                >
                    <GridToolbar>
                        <button
                            onClick={this.insert}
                            className="k-button"
                        >
                            Add New
                        </button>
                    </GridToolbar>
                    { this.columns.map((column, idx) =>
                          column.show && (<Column key={idx} field={column.field} title={column.title} />)
                     )}
                   
                
                   <Column   field="credentials" width="400px" cell={this.MyCustomCell}  title="Credentials" />
                    <Column
                        title="Actions"
                        cell={cellWithEditing( this.addToken,this.refresh)}
                    />
                </Grid>
                }
                {this.state.productInEdit && <DialogContainer dataItem={this.state.productInEdit} save={this.save} cancel={this.cancel} refresh={this.refresh}/>}

       
            </>
                    
        );
    }

    dialogTitle() {
        return `${this.state.productInEdit.ProductID === undefined ? 'Add' : 'Edit'} product`;
    }
    cloneProduct(product) {
        return Object.assign({}, product);
    }

    newProduct(source) {
        const newProduct = {
            CustomerId: this.generateId(),
            CustomerName: '',
            AdminName: "",
            AdminEmailId: ""
        };

        return Object.assign(newProduct, source);
    }

    generateId() {
        let id = 1;
        this.state.customers.forEach(p => { id = Math.max((Number(p.CustomerId) || 0) + 1, id); });
        return id;
    }
}

 

Customers.propTypes = {
    getCustomers: PropTypes.func.isRequired,
    transferToCustomer: PropTypes.func.isRequired,
    getCustomerBalance: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };
  
const mapStateToProps = state => ({
    customer: state.customer,
     auth: state.auth
});
  
export default connect(
    mapStateToProps,
    { getCustomers,transferToCustomer,getCustomerBalance }
)(Customers);

  
