import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";

import Navbar from "./components/layout/Navbar";
import Sidebar from "./components/layout/Sidebar";
import Footer from "./components/layout/Footer";
import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
 
import Reports from "./components/Reports/Reports";
 
import EditProfile from "./components/edit-profile/EditProfile";
 

import NotFound from "./components/not-found/NotFound";
import Settings from "./components/settings/Settings";
 
import CustomerActivation from "./components/customer-activation/CustomerActivation";
import CustomerActivationSuccess from "./components/customer-activation/CustomerActivationSuccess";
import Customers from "./components/Customers/Customers";

import "@progress/kendo-theme-default/dist/all.css";


import "./sb-admin-2.css";
import "./style.css";
import Users from "./components/Users/Users";
import Members from "./components/Members/Members";
import VideoPlayerDemo from "./components/Videoplayerdemo/Videoplayerdemo";
import FromUserToUser from "./components/Send/FromUserToUser";
import FromCustomerToUser from "./components/Send/FromCustomerToUser";

import APIDocs from "./components/APIDocs/APIDocs";

class App extends Component {
  render() {
    return (
      <Fragment>
        <Router>
          <div className="App">
            <div className="wrapper">
              <Sidebar />
              <div className="content-wrapper d-flex flex-column">
                <div className="content">
                  <Navbar />
                  <Route exact path="/not-found" component={NotFound} />
                  <Route exact path="/" component={Landing} />
                  <div className="container-fluid">
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/login" component={Login} />
                    <Switch>
                      <PrivateRoute
                        exact
                        path="/dashboard"
                        component={Dashboard}
                      />
                    
                      <PrivateRoute
                        exact
                        path="/edit-profile"
                        component={EditProfile}
                      />
                  
                      <PrivateRoute
                        exact
                        path="/settings"
                        component={Settings}
                      />
                   
                      <PrivateRoute
                        exact
                        path="/customer-activation"
                        component={CustomerActivation}
                      />
                     
                      <PrivateRoute
                        exact
                        path="/customer-activation-success"
                        component={CustomerActivationSuccess}
                      />
                   
                      <PrivateRoute
                        exact
                        path="/reports"
                        component={Reports}
                      />
                  
                      <PrivateRoute
                        exact
                        path="/customers"
                        component={Customers}
                      />
                   
                      <PrivateRoute
                        exact
                        path="/users"
                        component={Users}
                      />
                     
                      <PrivateRoute
                        exact
                        path="/members"
                        component={Members}
                      />
                                       
                      <PrivateRoute
                        exact
                        path="/video_player_demo"
                        component={VideoPlayerDemo}
                      />
                    <PrivateRoute
                        exact
                        path="/customertouser"
                        component={FromCustomerToUser}
                      />
                    <PrivateRoute
                        exact
                        path="/usertouser"
                        component={FromUserToUser}
                      />
                        <PrivateRoute
                        exact
                        path="/apidoc"
                        component={APIDocs}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
              <div />
            </div>
            <Footer />
          </div>
        </Router>
      </Fragment>
    );
  }
}

export default App;
