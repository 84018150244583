import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { clearCurrentProfile } from "../../actions/profileActions";
import { Menu, Sidebar, Responsive, Icon, Image,Button } from "semantic-ui-react";
class Navbar extends Component {
  state = { toggle: false };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();
  }
  toggleSideBar(e) {
    e.preventDefault();
    console.log("toggleSideBar clicked");
  }
 
  render() {
    const { isAuthenticated, user } = this.props.auth;

    const authLinks = (
      <nav
        className="navbar navbar-expand navbar-light  topbar  static-top shadow mb-4"
        style={{ backgroundColor: "white!important" }}
      >
        <a className="" href="index.html">
          <div className="sidebar-brand-icon " />
        </a>

        <button
          onClick={this.toggleSideBar.bind(this)}
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars" />
        </button>

 

        <ul className="navbar-nav ml-auto">
         

          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                {user.name}{" "}
              </span>
              <Icon name="user" />
            </a>

            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <Link to="/edit-profile" className="dropdown-item">
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />{" "}
                Profile
              </Link>
              <Link to="/settings" className="dropdown-item d-none">
                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />{" "}
                Settings
              </Link>
              
              <div className="dropdown-divider" />
              <a
                href=""
                onClick={this.onLogoutClick.bind(this)}
                className="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />{" "}
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
    );

const rightItems= [
  {
    content:(
      <Link   to="/login">
        <Button  style={{ color: "#fff", backgroundColor: "#6435C9" }}>
         Login
        </Button>
      </Link>
    ),
    key: "login"
  },
 /*
  {
    content: (
      <Link    to="/register">
        <Button  style={{ color: "#fff", backgroundColor: "rgb(109, 109, 209)" }}>
          Sign Up
        </Button>
      </Link>
    ),
    key: "register"
  }*/
]
    const guestLinks = (
      <Menu fixed="top" className="grad" borderless>
      <Menu.Item>
     
       <Link className="nav-link" to="/">
          <Image size="mini" src='/img/contentether_01.png' style={{display:"inline-block"}} /> ContentEther
       </Link>

      </Menu.Item>
      
      <Menu.Menu position="right">
        {rightItems.map(  item => (
          <Menu.Item {...item} />
        ))}
      </Menu.Menu>
    </Menu>
    );

    return <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>;
  }
}
Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser, clearCurrentProfile }
)(Navbar);
