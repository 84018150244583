
import React from 'react';
import ReactDOM from 'react-dom';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
 

import { Header, Icon, Button } from 'semantic-ui-react'
import { getUsers } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Users extends React.Component {
    state = {
        customers: [],
        user:[],
        productInEdit: undefined
    };
    componentDidMount() {
     this.props.getUsers()
     
      }
      componentWillReceiveProps(nextProps) {
       //   console.log("nextProps", nextProps);
        if (nextProps.errors) {
          this.setState({ errors: nextProps.errors });
        }
       
        const { users } = nextProps.auth;
      
        this.setState({ users });
      }
    edit = (dataItem) => {
        this.setState({ productInEdit: this.cloneProduct(dataItem) });
    }

    remove = (dataItem) => {
        const customers = this.state.customers.slice();
        const index = customers.findIndex(p => p.ProductID === dataItem.ProductID);
        if (index !== -1) {
            customers.splice(index, 1);
            this.setState({
                customers: customers
            });
        }
    }

    save = () => {
        const dataItem = this.state.productInEdit;
        const customers = this.state.customers.slice();

        console.log(customers)

        if (dataItem.CustomerId === undefined) {
            customers.unshift(this.newProduct(dataItem));
        } else {
            const index = customers.findIndex(p => p.CustomerId === dataItem.CustomerId);

            customers.splice(index, 1, dataItem);
        }

        this.setState({
            customers: customers,
            productInEdit: undefined
        });
    }

    cancel = () => {
        this.setState({ productInEdit: undefined });
    }

    insert = () => {
        this.setState({ productInEdit: { } });
    }

    render() {
        console.log(this.state.users)
        return (
            <div >

                <Header as='h2' icon textAlign='center'>
                <Icon name='users' circular />
                <Header.Content>Users</Header.Content>
                </Header>
                <Grid
                    data={this.state.users}
                    style={{ height: '420px' }}
                >
             
                    <Column field="_id" title="Id"   />
                    <Column field="email" title="Email" />
                    <Column field="name" title="User Name" />
                    <Column field="wallet_address" title="Wallet address" />
                    <Column field="access" title="Access level" />
                
                   
                     
                </Grid>
                
            </div>
        );
    }

    dialogTitle() {
        return `${this.state.productInEdit.ProductID === undefined ? 'Add' : 'Edit'} product`;
    }
    cloneProduct(product) {
        return Object.assign({}, product);
    }

    newProduct(source) {
        const newProduct = {
            CustomerId: this.generateId(),
            CustomerName: '',
            AdminName: "",
            AdminEmailId: ""
        };

        return Object.assign(newProduct, source);
    }

    generateId() {
        let id = 1;
        this.state.customers.forEach(p => { id = Math.max((Number(p.CustomerId) || 0) + 1, id); });
        return id;
    }
}

 

Users.propTypes = {
    getUsers: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };
  
const mapStateToProps = state => ({
 
     auth: state.auth
});
  
export default connect(
    mapStateToProps,
    { getUsers }
)(Users);

  
