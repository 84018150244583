import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { registerUser, activateUser } from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";
import SelectListGroup from "../common/SelectListGroup";

class CustomerActivation extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      companyname: "",
      email: "",
      password: "",
      password2: "",
      packagetype: "",
      payment: "",
      errors: {}
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      //   this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();

    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      packagetype: this.state.packagetype,
      payment: this.state.payment,
      companyname: this.state.companyname
    };
    //console.log(newUser)
    this.props.activateUser(newUser, this.props.history);
  }

  render() {
    //get errors from state
    const { errors } = this.state;
    // Select options for packagetype
    const options = [
      { label: "* Select Interplay Package", value: null },
      { label: "Starter ", value: 1 },
      { label: "Professional", value: 2 },
      { label: "Enterprise", value: 3 }
    ];
    // Select options for Payment Method
    const options2 = [
      { label: "* Select Payment Method", value: null },
      { label: "Purchase Order ", value: 1 },
      { label: "Corporate Creditcard", value: 2 }
    ];
    return (
      <div className="register">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">Customer Activation</h1>
              <p className="lead text-center">Create your Interplay account</p>
              <form className="text-center" onSubmit={this.onSubmit}>
                <SelectListGroup
                  placeholder="Package type"
                  name="packagetype"
                  value={this.state.packagetype}
                  onChange={this.onChange}
                  options={options}
                  error={errors.packagetype}
                />
                <SelectListGroup
                  placeholder="Payment type"
                  name="payment"
                  value={this.state.payment}
                  onChange={this.onChange}
                  options={options2}
                  error={errors.payment}
                />
                <TextFieldGroup
                  placeholder="Customer Name"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                />
                <TextFieldGroup
                  placeholder="Company Name"
                  name="companyname"
                  value={this.state.companyname}
                  onChange={this.onChange}
                  error={errors.companyname}
                />
                <TextFieldGroup
                  placeholder="Admin Email Address"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />

                <TextFieldGroup
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                />
                <TextFieldGroup
                  placeholder="Confirm Password"
                  name="password2"
                  type="password"
                  value={this.state.password2}
                  onChange={this.onChange}
                  error={errors.password2}
                />

                <input type="submit" className=" mr-sm-2 button w-button" />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
CustomerActivation.propTypes = {
  registerUser: PropTypes.func.isRequired,
  activateUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser, activateUser }
)(withRouter(CustomerActivation));
