import axios from "axios";

import {
  GET_MEMBERS,GET_MEMBER,
  MEMBER_LOADING,
  GET_ERRORS,
  TRANSFER_TO_MEMBER,
  GET_MEMBER_BALANCE,
  REDEEM_USER_TO_CUSTOMER
}
  from "./types";

// Get all members
export const getMembers = () => dispatch => {
  dispatch(setMemberLoading());
  console.log("getMembers data")
  axios
    .get("/api/members")
    .then(res =>
      dispatch({
        type: GET_MEMBERS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_MEMBERS,
        payload: {}
      })
    );
};


// Add token to  member
export const transferToMember = (data) => dispatch => {
  dispatch(setMemberLoading());
  console.log("transferToMember data=",data)
  axios
    .post("/api/members/transfertomember",data)
    .then(res =>{
      dispatch({
        type: TRANSFER_TO_MEMBER,
        payload: res.data
      })

    }

    )
    .catch(err =>
      dispatch({
        type: TRANSFER_TO_MEMBER,
        payload: {}
      })
    );
};

// Redeem CEToken from User to Customer
export const redeemUserToCustomer = (data) => dispatch => {
  dispatch(setMemberLoading());
  console.log("redeemusertocustomer ",data)
  axios
    .post("/api/members/redeemusertocustomer",data)
    .then(res =>{
      dispatch({
        type: REDEEM_USER_TO_CUSTOMER,
        payload: res.data
      })
      dispatch(getMemberBalance(data))
    }

    )
    .catch(err =>
      dispatch({
        type: REDEEM_USER_TO_CUSTOMER,
        payload: {}
      })
    );
};



// get Customer Balance
export const getMemberBalance = (member) => dispatch => {
 
  console.log("---getMemberBalance ",member)
  const data ={
    id:member._id,
    "type": "user",
  "name": member.name,
  "username": member.username,
  "customer_username": member.customer_username,
  "api_version":"1"}


  axios
    .post("/api/members/getmemberbalance",data)
    .then(res =>
      
      dispatch({
        type: GET_MEMBER_BALANCE,
        payload: res.data
      })
      

    )
    .catch(err =>
      dispatch({
        type: GET_MEMBER_BALANCE,
        payload: {}
      })
    );
};


// Member loading
export const setMemberLoading = () => {
  return {
    type: MEMBER_LOADING
  };
};

 


