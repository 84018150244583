
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { Button } from 'semantic-ui-react'
export default function cellWithEditing( addToken,refresh) {
    return class extends GridCell {
        render() {
            return (
                <td>
                    <Button.Group  >
                        <Button  
                        onClick={() => {
                            addToken(this.props.dataItem);
                        }}> Add Token</Button>
                       <Button  
                        onClick={() => {
                            refresh(this.props.dataItem);
                        }}> Refresh</Button>
                    </Button.Group>
                </td>
            );
        }
    };
}

