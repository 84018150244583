
import React from 'react';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';

import DialogContainer from './DialogContainer';
import cellWithEditing from './cellWithEditing';

import { Header, Icon, Button } from 'semantic-ui-react'
import { getCustomers } from "../../actions/customerActions";
import { getMembers,transferToMember,getMemberBalance } from "../../actions/memberActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CustomCell from "./CustomCell"
import { orderBy } from '@progress/kendo-data-query';

class Members extends React.Component {
    state = {
 
        member:[],
        productInEdit: undefined,
        sort: [
            { field: 'customer_username', dir: 'asc' },
            { field: 'username', dir: 'asc' },
            { field: 'name', dir: 'asc' },
        ]
    };
    componentDidMount() {
     this.props.getMembers()
  
     console.log("componentDidMount" );
     
      }
      componentWillReceiveProps(nextProps) {
           console.log("nextProps", nextProps);
        if (nextProps.errors) {
          this.setState({ errors: nextProps.errors });
        }
       
        const { members } = nextProps.member;
        console.log("nextProps", members);
      
        this.setState({ members });
      }
    edit = (dataItem) => {
        this.setState({ productInEdit: this.cloneProduct(dataItem) });
    }

    remove = (dataItem) => {
        const customers = this.state.members.slice();
        const index = customers.findIndex(p => p.ProductID === dataItem.ProductID);
        if (index !== -1) {
            customers.splice(index, 1);
            this.setState({
                customers: customers
            });
        }
    }
    refresh = (dataItem) => {
        
         this.props.getMemberBalance(dataItem)
    }
    addToken = (dataItem) => {
       
        dataItem.amount=1
        dataItem.network="Rinkeby Test Network"
        const productInEdit=this.cloneProduct(dataItem)
        console.log("addToken,",productInEdit)
        this.setState({ productInEdit  });
       
    }
    save = () => {
        const dataItem = this.state.productInEdit;
        const members = this.state.members.slice();
        console.log("save dataItem=",dataItem)
        console.log("save members=",members)
        if( parseInt(dataItem.amount) > 0 ){
            console.log("TODO add amount to member from Customer/Company",parseInt(dataItem.amount))
            console.log("data from input form ",dataItem)
            this.props.transferToMember(dataItem)
            this.props.getMemberBalance(dataItem)
            
        }
/*
        if (dataItem.CustomerId === undefined) {
            members.unshift(this.newProduct(dataItem));
        } else {
            const index = members.findIndex(p => p.CustomerId === dataItem.CustomerId);

            members.splice(index, 1, dataItem);
        }*/



        this.setState({
            members,
            productInEdit: undefined
        });
    }

    cancel = () => {
        this.setState({ productInEdit: undefined });
    }

    insert = () => {
        this.setState({ productInEdit: { } });
    }
    columns = [
        {
          title: 'Type',
          field: 'type',
          show: false
        },
        {
          title: 'Customer(customer_username)',
          field: 'customer_username',
          show: true
        },
        {
          title: 'User Name',
          field: 'username',
          show: true
        },
        {
            title: 'Email (name)',
            field: 'name',
            show: false
          },
        {
            title: 'Api Ver Name',
            field: 'api_version',
            show: false
          },
          {
            title: 'Status',
            field: 'status',
            show: false
          },
            
      ]
      MyCustomCell = (props) => <CustomCell {...props}   />

    render() {
        const {members} =this.state
 
        const isEmpty=JSON.stringify(members) === "{}" || typeof members ==="undefined" ||  members === null
        console.log("isEmpty",isEmpty)
      
        return (
            <>
   
            <Header as='h2' icon textAlign='center'>
            <Icon name='users' circular />
            <Header.Content>Members</Header.Content>
            </Header>
            {!isEmpty &&
            <Grid
            data={orderBy(members, this.state.sort)}
            style={{ height: '920px' }}
            sortable
            sort={this.state.sort}
            onSortChange={(e) => {
                
                this.setState({
                    sort: e.sort
                });
            }}
            >
                <GridToolbar>
                    <button
                        onClick={this.insert}
                        className="k-button"
                    >
                        Add New
                    </button>
                </GridToolbar>
                { this.columns.map((column, idx) =>
                      column.show && (<Column key={idx} field={column.field} title={column.title} />)
                 )}
               
            
               <Column   field="credentials" width="400px" cell={this.MyCustomCell}  title="Credentials" />
                <Column
                    title="Actions"
                    cell={cellWithEditing( this.addToken,this.refresh)}
                />
            </Grid>
            }
            {this.state.productInEdit && <DialogContainer dataItem={this.state.productInEdit} save={this.save} cancel={this.cancel} refresh={this.refresh}/>}

   
        </>
        );
    }

    dialogTitle() {
        return `${this.state.productInEdit.ProductID === undefined ? 'Add' : 'Edit'} product`;
    }
    cloneProduct(product) {
        return Object.assign({}, product);
    }

    newProduct(source) {
        const newProduct = {
            customer_name: "",
            user_name: '',
            loyalty_points: "",
            email: "" 
        };

        return Object.assign(newProduct, source);
    }

    generateId() {
        let id = 1;
        this.state.customers.forEach(p => { id = Math.max((Number(p.CustomerId) || 0) + 1, id); });
        return id;
    }
}

 

Members.propTypes = {
    getCustomers: PropTypes.func.isRequired,
    getMembers: PropTypes.func.isRequired,
    getMemberBalance: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };
  
const mapStateToProps = state => ({
    customer: state.customer,
    member:state.member,
    auth: state.auth
});
  
export default connect(
    mapStateToProps,
    { getCustomers,getMembers,getMemberBalance,transferToMember }
)(Members);

  
