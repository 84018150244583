
import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { Button, Checkbox, Form } from 'semantic-ui-react'
export default class DialogContaincer extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          productInEdit: this.props.dataItem || null,
          amount:0
      };
      
  }
  componentDidMount(){

    const {productInEdit}= this.state
    productInEdit.amount=0;
    console.log(productInEdit)

    this.setState({productInEdit})
  }
  handleSubmit(event) {
    //  event.preventDefault();

      const {productInEdit,amount} = this
      console.log(amount,productInEdit)
  }

  onDialogInputChange = (event) => {
      let target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.props ? target.props.name : target.name;

      const edited = this.state.productInEdit;
      edited[name] = value;

      this.setState({
          productInEdit: edited
      });
  }

  render() {
    const {productInEdit}= this.state
    console.log("DialogContainer productInEdit ",productInEdit)
      return (
        <Dialog width={500} height={350} title={"Add CEToken to Customer"}
            onClose={this.props.cancel}
        >
            <Form onSubmit={this.handleSubmit}>
                <Form.Field>
                <label> Customer Name</label>
                <input  type="text" placeholder="Company Name"
                    name="username" disabled
                    value={this.state.productInEdit.username || ''}
                    onChange={this.onDialogInputChange} />
                </Form.Field>
                <Form.Field>
                <label> Admin Email Id</label>
                <input   type="text" placeholder="Email Id"
                    name="name" disabled
                    value={this.state.productInEdit.name || ''}
                    onChange={this.onDialogInputChange} />
                </Form.Field>
                <Form.Field>
                <label>CEToken Amount (Min 1, Max 1,000)</label>
                <input   type="text" placeholder="0"
                    name="amount" min="0" max="1000"
                    value={this.state.productInEdit.amount || 0}
                    onChange={this.onDialogInputChange} />
                </Form.Field>
               
            </Form>
  
            <DialogActionsBar>
            <button
                className="k-button"
                onClick={this.props.cancel}
            >
                Cancel
                            </button>
            <button
                className="k-button k-primary"
                onClick={this.props.save}
            >
                Save
                            </button>
            </DialogActionsBar>
        </Dialog>
    );
  }
}

