import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import TextFieldGroup from "../common/TextFieldGroup";
import { createProfile, getCurrentProfile,deleteAccount } from "../../actions/profileActions";
import isEmpty from "../../validation/is-empty";
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import {Link} from 'react-router-dom'
class CreateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handle: "",
      firstname: "",
      lastname: "",
      phone: "",
      usertype: "",
      status: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentProfile();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.profile.profile) {
      const profile = nextProps.profile.profile;

      // If profile field doesnt exist, make empty string
      profile.firstname = !isEmpty(profile.firstname) ? profile.firstname : "";
      profile.website = !isEmpty(profile.lastname) ? profile.lastname : "";
      profile.location = !isEmpty(profile.phone) ? profile.phone : "";

      // Set component fields state
      this.setState({
        handle: profile.handle,
        firstname: profile.firstname,
        lastname: profile.lastname,
        phone: profile.phone
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const profileData = {
      handle: this.state.handle,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      phone: this.state.phone
    };

    this.props.createProfile(profileData, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onDeleteClick(e) {
    this.props.deleteAccount();
  }
  
  render() {
    const { errors } = this.state;
   

    return (
      <Grid textAlign='center' style={{ height: '50vh',color:"#6435C9" }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2'  style={{ color:"#6435C9" }}   textAlign='center'>
       Edit Profile
        <small className="d-block pb-3">* = required fields</small>
        </Header>
        <Form size='large' noValidate onSubmit={this.onSubmit}>
          <Segment stacked>
          <TextFieldGroup
                  placeholder="* Username"
                  name="handle"
                  value={this.state.handle}
                  onChange={this.onChange}
                  error={errors.handle}
                  info="Your Username "
                />

                <TextFieldGroup
                  placeholder="* First name"
                  name="firstname"
                  value={this.state.firstname}
                  onChange={this.onChange}
                  error={errors.firstname}
                  info="Your First name "
                />
                <TextFieldGroup
                  placeholder="* Last name"
                  name="lastname"
                  value={this.state.lastname}
                  onChange={this.onChange}
                  error={errors.lastname}
                  info="Your Last name "
                />
             
                <TextFieldGroup
                  placeholder="Contact Number "
                  name="phone"
                  value={this.state.phone}
                  onChange={this.onChange}
                  error={errors.phone}
                  info="Enter Phone Number or Mobile"
                />

  
  
            <Button style={{ backgroundColor:"#6435C9",color:"white" }}  fluid size='large' type="submit" >
              Submit
            </Button>
          </Segment>
        </Form>
        
      </Grid.Column>
    </Grid>
    );
  }
}

CreateProfile.propTypes = {
  createProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { createProfile, getCurrentProfile,deleteAccount }
)(withRouter(CreateProfile));
