import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {}
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData);
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
   
    return (

      <Grid textAlign='center' style={{ height: '60vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' color='violet' textAlign='center'>
         Log-in to your account
        </Header>
        <Form size='large' onSubmit={this.onSubmit}>
          <Segment stacked>
            <TextFieldGroup
                  placeholder="Email Address"
                  name="email"
                  type="email"
                  icon="user"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />
         

            <TextFieldGroup
                   placeholder="Password"
                   name="password"
                   type="password"
                   icon="lock"
                   value={this.state.password}
                   onChange={this.onChange}
                   error={errors.password}
                />

  
  
            <Button color='violet' fluid size='large' type="submit" >
              Login
            </Button>
          </Segment>
        </Form>
       {/* <Message>
          New to us? <Link to="/register">Sign Up</Link>
       </Message> */}
      </Grid.Column>
    </Grid>


    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(Login);
