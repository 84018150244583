import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProfile, deleteAccount } from '../../actions/profileActions';

import { Grid, Image } from 'semantic-ui-react'


 
import MainContainer from '../../pages/MainContainer'
import Footer from '../Footer/Footer';
import { Card, Container } from 'semantic-ui-react';

import  LineChartComponent from '../Chart/LineChartComponent'
import  PieChartComponent from '../Chart/PieChartComponent'
import  LineChartComponent2 from '../Chart/LineChartComponent2'
import  AreaChartComponent from '../Chart/AreaChartComponent'
class Dashboard extends Component {
  componentDidMount() {
   // this.props.getCurrentProfile();
  }

  onDeleteClick(e) {
    this.props.deleteAccount();
  }

  render() {
    

    console.log(this.state)

    return (
    
        <div>
              <Grid columns={2} divided>
                <Grid.Row>
                  <Grid.Column>
                    <Card>
                      <Card.Content>
                        <Card.Header content='20' />
                        <Card.Meta content='Total Customers' />
                        <Card.Description>
                           <LineChartComponent />
                        </Card.Description>  
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column>
                    <Card>
                      <Card.Content>
                      <Card.Header content='50' />
                        <Card.Meta content='Total Users' />
                        <Card.Description>
                          <AreaChartComponent/>
                        </Card.Description>  
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column>
                 
                  </Grid.Column>
              
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Card>
                      <Card.Content>
                        <Card.Header content=' 125' />
                        <Card.Meta content='Total Loyalty Points' />
                        <Card.Description>
                           <LineChartComponent2 />
                        </Card.Description>  
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column>
                    <Card>
                      <Card.Content>
                      <Card.Header content=' 321' />
                        <Card.Meta content='Total Transactions' />
                        <Card.Description>
                          <PieChartComponent/>
                        </Card.Description>  
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column>
                 
                  </Grid.Column>
              
                </Grid.Row>

                
              </Grid>
         

        </div>
       
        
    );
  }
}

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
  Dashboard
);
