import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
} from 'semantic-ui-react'
import "./APIDocs.css"


const APIDocs = () => (
  <div>
    <Segment   vertical style={{ margin: '1em 0em 0em', padding: '1em 0em' }}>
      <Container textAlign='left' fluid>
      <Header as='h1'>ContentEther Rest API - V1.1</Header>
      <Divider/>
      <List ordered>
        <List.Item as='a' href="#section01" >Get oAuth 2.0 Access Token</List.Item>
        <List.Item as='a'  href="#section02">Create Customer</List.Item>
        <List.Item as='a' href="#section03" > Add CEToken(s) to Customer</List.Item>
        <List.Item as='a' href="#section04">Get Customer CEToken(s) Balance</List.Item>
        <List.Item as='a' href="#section05">Create User under a Customer</List.Item>
        <List.Item as='a' href="#section06">Transfer CEToken(s) to User from Customer</List.Item>
        <List.Item as='a' href="#section07">Get User CEToken(s) Balance</List.Item>
        <List.Item as='a' href="#section08">Redeem User CEToken(s) to Customer</List.Item>
        <List.Item as='a' href="#section09">Transfer CEToken(s) from User1 to User2</List.Item>
        <List.Item as='a' href="#section10">Activate/De-activate a Customer</List.Item>
        <List.Item as='a' href="#section11">Activate/Deactivate an User</List.Item>
        <List.Item as='a' href="#section12">Get Transactions of a Customer</List.Item>
        <List.Item as='a' href="#section13">Get Transactions of an User</List.Item>

      </List>
    
     
      <ScrollableAnchor id="section01"> 
        <div>
          <br/>
          <Header as='h3'>1) Get oAuth 2.0 Access Token</Header>
        </div>
      </ScrollableAnchor>
      <Divider/>
    
      <pre>Request:{'\n'}
POST /api/token{'\n'}
HTTP Header - Basic Authentication (Authorization: Basic{'\n'}
dGVzdGNsaWVudDp0ZXN0cGFzcw==){'\n'}
Content-Type: application/x-www-form-urlencoded{'\n'}
Body- grant_type=client_credentials{'\n'}
Response output:{'\n'}
{'{'} "access_token":"3a34b6e898d90281f45cd6a9c59249a8af736a59","expires_in":{'\n'}
3600,"token_type":"Bearer","scope":null{'}'}{'\n'}
For Example:{'\n'}
curl -u testclient:testpass https://crypto.ainewsupdate.com/api/token -d{'\n'}
'grant_type=client_credentials' -v{'\n'}
* Trying 144.217.68.211...{'\n'}
* TCP_NODELAY set{'\n'}
* Expire in 200 ms for 4 (transfer 0x7f81b3011e00){'\n'}
* Connected to crypto.ainewsupdate.com (144.217.68.211) port 443 (#0){'\n'}
* ALPN, offering http/1.1{'\n'}
* Cipher selection:{'\n'}
ALL:!EXPORT:!EXPORT40:!EXPORT56:!aNULL:!LOW:!RC4:@STRENGTH{'\n'}
* successfully set certificate verify locations:{'\n'}
* CAfile: /opt/local/share/curl/curl-ca-bundle.crt{'\n'}
CApath: none{'\n'}
* TLSv1.2 (OUT), TLS header, Certificate Status (22):{'\n'}
* TLSv1.2 (OUT), TLS handshake, Client hello (1):{'\n'}
* TLSv1.2 (IN), TLS handshake, Server hello (2):{'\n'}
* TLSv1.2 (IN), TLS handshake, Certificate (11):{'\n'}
* TLSv1.2 (IN), TLS handshake, Server key exchange (12):{'\n'}
* TLSv1.2 (IN), TLS handshake, Server finished (14):{'\n'}
* TLSv1.2 (OUT), TLS handshake, Client key exchange (16):{'\n'}
* TLSv1.2 (OUT), TLS change cipher, Change cipher spec (1):{'\n'}
* TLSv1.2 (OUT), TLS handshake, Finished (20):{'\n'}
* TLSv1.2 (IN), TLS change cipher, Change cipher spec (1):{'\n'}
* TLSv1.2 (IN), TLS handshake, Finished (20):{'\n'}
* SSL connection using TLSv1.2 / ECDHE-RSA-AES256-GCM-SHA384{'\n'}
* ALPN, server accepted to use http/1.1{'\n'}
* Server certificate:{'\n'}
* subject: CN=crypto.ainewsupdate.com{'\n'}
* start date: Feb 26 04:53:06 2019 GMT{'\n'}
* expire date: May 27 04:53:06 2019 GMT{'\n'}
* subjectAltName: host "crypto.ainewsupdate.com" matched cert's{'\n'}
"crypto.ainewsupdate.com"{'\n'}
* issuer: C=US; O=Let's Encrypt; CN=Let's Encrypt Authority X3{'\n'}
* SSL certificate verify ok.{'\n'}
* Server auth using Basic with user 'testclient'{'\n'}
> POST /api/token HTTP/1.1{'\n'}
> Host: crypto.ainewsupdate.com{'\n'}
> Authorization: Basic dGVzdGNsaWVudDp0ZXN0cGFzcw=={'\n'}
> User-Agent: curl/7.64.0{'\n'}
> Accept: */*{'\n'}
> Content-Length: 29{'\n'}
> Content-Type: application/x-www-form-urlencoded{'\n'}
>{'\n'}
* upload completely sent off: 29 out of 29 bytes{'\n'}
{'<'} HTTP/1.1 200 OK{'\n'}
{'<'}Date: Tue, 12 Mar 2019 08:39:06 GMT{'\n'}
{'<'} Server: Apache/2.4.18 (Ubuntu){'\n'}
{'<'} Cache-Control: no-store{'\n'}
{'<'}Pragma: no-cache{'\n'}
{'<'} Content-Length: 112{'\n'}
{'<'} Content-Type: application/json{'\n'}
{'<'}{'\n'}
* Connection #0 to host crypto.ainewsupdate.com left intact{'\n'}
{'{'}"access_token":"a3ecdb00b7de14229a300aa7e4f0b2b8639e33f0","expires_in":{'\n'}
3600,"token_type":"Bearer","scope":null{'}'}{'\n'}
</pre>


<ScrollableAnchor id="section02"> 
  <div>
    <br/>
    <Header as='h3'>2) Create Customer</Header>
  </div>
</ScrollableAnchor>
<Divider/>


<pre>

Request:{'\n'}
POST /api/create_customer{'\n'}
HTTP Header - oAuth 2.0 token ( [Authorization] => Bearer
7cddf943078f158e6130d90aedd06856395aa6e0 ){'\n'}
Content-Type: application/json{'\n'}
Body:
{'{'}
"type": "customer",{'\n'}
"name":"contentether",{'\n'}
"username":"arul@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"status":"active"{'\n'}
}{'\n'}
Response output:{'\n'}
SUCCESS OUTPUT{'\n'}
{'{'}{'\n'}
"type":"customer",{'\n'}
"name":"contentether",{'\n'}
"username":"arul@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"status":"active",{'\n'}
"result":"success",{'\n'}
"credentials":{'{'}{'\n'}
"Main Ethereum Network":{'{'}{'\n'}
"address":"0x3697d7d9f091c2c77aa03dbaccb0c3f1bf6b0b1b",{'\n'}
"CEToken":"0.96598",{'\n'}
"USD":"7.96",{'\n'}
"ETH":"0.000",{'\n'}
"status":"active"},{'\n'}
"Rinkeby Test Network":{'{'}{'\n'}
"address":"0x6934d6470a149d19843c67b693629e21d66283bf",{'\n'}
"CEToken":"0.740601",{'\n'}
"USD":"354.94",{'\n'}
"ETH":"0.000",{'\n'}
"status":"active"},{'\n'}
"Private Ethereum Network":{'{'}{'\n'}
"address":"0x2a1cb024dfa7b40b5212f0f00c8f720b0e058947",{'\n'}
"CEToken":"0.96558",{'\n'}
"USD":"5.96",{'\n'}
"ETH":"0.000",{'\n'}
"status":"active"}{'\n'}
}{'\n'}

}{'\n'}
ERROR OUTPUT{'\n'}
The following errors can come as output response.{'\n'}
{'{'}"error":"invalid_token","error_description":"The access token provided is
invalid"}{'\n'}
{'{'}"error":"invalid_token","error_description":"The access token provided has
expired"}{'\n'}
{'{'}"result":"error","error":"invalid_input","error_description":"The input JSON
payload is invalid"}{'\n'}
{'{'}"result":"error","error":"username_exists_already","error_description":"The
specified username exists already"}{'\n'}
For Example:{'\n'}
ERROR RESPONSES:{'\n'}
{'{'}{'\n'}
"type":"customer",{'\n'}
"name":"contentether",{'\n'}
"username":"arul@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"status":"active",{'\n'}
"result":"error",{'\n'}
"error":"username_exists_already",{'\n'}
"error_description":"The specified username exists already"{'\n'}
}{'\n'}

</pre>



<ScrollableAnchor id="section03"> 
  <div>
    <br/>
    <Header as='h3'>3. Add CEToken(s) to Customer</Header>
  </div>
</ScrollableAnchor>
<Divider/>

<pre>
Request:{'\n'}
POST /api/transferToCustomer{'\n'}
HTTP Header - oAuth 2.0 token ( [Authorization] => Bearer
7cddf943078f158e6130d90aedd06856395aa6e0 ){'\n'}
Content-Type: application/json{'\n'}
Body:
{'{'}
"type": "customer",{'\n'}
"name":"contentether",{'\n'}
"username":"arul@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"network":"Rinkeby Test Network",{'\n'}
"CEToken":"0.000000001"{'\n'}
}{'\n'}
Responses:{'\n'}
{'{'}{'\n'}
"type":"customer",{'\n'}
"name":"contentether",{'\n'}
"username":"testadmin@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"network":"Rinkeby Test Network",{'\n'}
"CEToken":"0.000000001",{'\n'}
"result":"success",{'\n'}
"transactionID":"0x497c36df73cd4e10a8e5b7b22cc83e65c6ef3b593a3fa
5e67b43c464e27ce78b"{'\n'}
}{'\n'}
To see the transaction related information use the following link:{'\n'}
https://rinkeby.etherscan.io/tx/{'<'}TRANSACTION_ID_FROM_RESPONSE >{'\n'}
For Example:{'\n'}
https://rinkeby.etherscan.io/tx/0x497c36df73cd4e10a8e5b7b22cc83e65c6ef3
b593a3fa5e67b43c464e27ce78b{'\n'}
ERROR OUTPUT{'\n'}
The following errors can come as output response.
{'{'}"error":"invalid_token","error_description":"The access token provided is
invalid"}{'\n'}

{'{'}"error":"token_expired","error_description":"The access token provided has
expired"}{'\n'}
{'{'}"result":"error","error":"invalid_input","error_description":"The input JSON
payload is invalid"}{'\n'}
{'{'}"result":"error","error":"insufficient_balance","error_description":"Account
balance is insufficient."}{'\n'}
{'{'} "result":"error","error":"account_error ","error_description":"Account is
disabled or temporarily suspended. {'\n'}Please contact customer care for further
assistance."}{'\n'}
{'{'}"result":"error","error":"no_such_customer","error_description":"The specified
username does not exist"}{'\n'}
{'{'}"result":"error","error":"transaction_error","error_description":"Insufficient
balance for the transaction"}{'\n'}
{'{'}"type":"customer","name":"contentether","username":"testadmin@contentethe
r.com","api_version":"1",{'\n'}"network":"Rinkeby Test
Network","CEToken":"0.000000001","result":"error","error":"transaction_error",{'\n'}
"error_description":"Failed to retrieve ERC20 token decimals\/scale value"}{'\n'}
{'{'}"type":"customer","name":"contentether","username":"testadmin@contentethe
r.com","api_version":"1",{'\n'}"network":"Rinkeby Test
Network","CEToken":"0.000000001","result":"error","error":"transaction_error",{'\n'}
"error_description":"insufficient funds for gas * price + value"}{'\n'}
{'{'} "result":"error","error":"invalid_network","error_description":"The network
specified is invalid"}{'\n'}
List of Valid Networks:{'\n'}
• Main Ethereum Network: Ethereum public main network{'\n'}
• Rinkeby Test Network: Rinkeby, the public Geth PoA {'\n'}testnet
• Private Ethereum Network: ContentEther, Ethereum private network{'\n'}
•
0: Olympic, Ethereum public pre-release testnet{'\n'}
•
1: Frontier, Homestead, Metropolis, the Ethereum public main network{'\n'}
•
1: Classic, the (un)forked public Ethereum Classic main network, chain ID 61{'\n'}
•
1: Expanse, an alternative Ethereum implementation, chain ID 2{'\n'}
•
2: Morden, the public Ethereum testnet, now Ethereum Classic testnet{'\n'}
•
3: Ropsten, the public cross-client Ethereum testnet{'\n'}
•
4: Rinkeby, the public Geth PoA testnet{'\n'}
•
5: Guerli, the public Geth/Parity PoA testnet{'\n'}
•
8: Ubiq, the public Gubiq main network with flux difficulty chain ID 8{'\n'}
•
42: Kovan, the public Parity PoA testnet{'\n'}
•
77: Sokol, the public POA Network testnet{'\n'}
•
99: Core, the public POA Network main network{'\n'}
•
100: xDai, the public MakerDAO/POA Network main network{'\n'}
•
401697: Tobalaba, the public Energy Web Foundation testnet
{'\n'}
•
7762959: Musicoin, the music blockchain{'\n'}
•
61717561: Aquachain, ASIC resistant chain{'\n'}
•
[Other]: Could indicate that your connected to a local development test network.{'\n'}
</pre>

 
<ScrollableAnchor id="section04"> 
  <div>
    <br/>
    <Header as='h3'>4. Get Customer CEToken(s) Balance</Header>
  </div>
</ScrollableAnchor>
<Divider/>

<pre>

Request:{'\n'}
POST /api/getCustomerBalance{'\n'}
HTTP Header - oAuth 2.0 token ( [Authorization] => Bearer
7cddf943078f158e6130d90aedd06856395aa6e0 ){'\n'}
Content-Type: application/json{'\n'}
Body:{'\n'}
{'{'}
"type": "customer",{'\n'}
"name":"contentether",{'\n'}
"username":"admin@contentether.com",{'\n'}
"api_version":"1"{'\n'}
}{'\n'}
Responses:{'\n'}
{'{'}
"type":"customer",{'\n'}
"name":"contentether",{'\n'}
"username":"admin@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"result":"success",{'\n'}
"credentials":{'{'}{'\n'}
"Main Ethereum Network":{'{'}{'\n'}
"address":"0x34620efd20ee214d56441527b250aca7f2551c92",{'\n'}
"ETH":"0x0",{'\n'}
"CEToken":"0.000000000000000000",{'\n'}
"USD":"0.00",{'\n'}
"status":"active"},{'\n'}
"Rinkeby Test Network":{'{'}{'\n'}
"address":"0xa3767871c740f8e25397dc693820a27b875b1c47",{'\n'}
"ETH":"0x271559e92c9000",{'\n'}
"CEToken":"0.000000100",{'\n'}
"USD":"0.00",{'\n'}
"status":"active"},{'\n'}
"Private Ethereum Network":{'{'}{'\n'}
"address":"0x4d96c52f51dacbc4e302b7d450f16d61d2c8edb8",{'\n'}
"ETH":"0x0",{'\n'}
"CEToken":"0.00",{'\n'}
"USD":"0.00",{'\n'}
"status":"active"}{'\n'}
}{'\n'}
}{'\n'}
ERROR OUTPUT{'\n'}
The following errors can come as output response.{'\n'}
{'\n'}
{'{'}"error":"invalid_token","error_description":"The access token provided is
invalid"}{'\n'}
{'{'}"error":"token_expired","error_description":"The access token provided has
expired"}{'\n'}
{'{'} "result":"error","error":"invalid_input","error_description":"The input JSON
payload is invalid"}{'\n'}
{'{'}"result":"error","error":"no_such_customer","error_description":"The specified
username does not exist"}{'\n'}
{'\n'}
</pre>


<ScrollableAnchor id="section05"> 
  <div>
    <br/>
    <Header as='h3'>5. Create User under a Customer</Header>
  </div>
</ScrollableAnchor>
<Divider/>
     
 

<pre>


Request:{'\n'}
POST /api/create_user{'\n'}
HTTP Header - oAuth 2.0 token ( [Authorization] => Bearer
7cddf943078f158e6130d90aedd06856395aa6e0 ){'\n'}
Content-Type: application/json{'\n'}
Body:{'\n'}
{'{'}{'\n'}
"type": "user",{'\n'}
"name":"testuser",{'\n'}
"username":"testuser@contentether.com",{'\n'}
"customer_username":"testadmin@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"status":"active"{'\n'}
}{'\n'}{'\n'}
Response output:{'\n'}{'\n'}
SUCCESS OUTPUT{'\n'}
{'{'}{'\n'}
"type":"user",{'\n'}
"name":"testuser",{'\n'}
"username":"testuser@contentether.com",{'\n'}
"customer_username":"testadmin@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"status":"active",{'\n'}
"result":"success",{'\n'}
"credentials":{'{'}{'\n'}
"Main Ethereum Network":{'{'}{'\n'}
"address":"0x1af407327e2bd0ce3a68849ef4a18ec8220ba7b2",{'\n'}
"CEToken":"0.00",{'\n'}
"USD":"0.00",{'\n'}
"ETH":"0x00",{'\n'}
"status":"active"},{'\n'}
"Rinkeby Test Network":{'{'}{'\n'}
"address":"0x188eaa9067e6e4bb0771c60ed29dc1065799ee30",{'\n'}
"CEToken":"0.000000001",{'\n'}
"USD":"0.00",{'\n'}
"ETH":"0x00",{'\n'}
"status":"active"},{'\n'}
"Private Ethereum Network":{'{'}{'\n'}
"address":"0x3a28017178d5982e6975a4d8320dcab0ff3b9b43",{'\n'}
"CEToken":"0.00",{'\n'}
"USD":"0.00",{'\n'}
"ETH":"0.000",{'\n'}
"status":"active"}{'\n'}
}{'\n'}
}{'\n'}
ERROR OUTPUT{'\n'}{'\n'}
The following errors can come as output response.{'\n'}
{'{'}"error":"invalid_token","error_description":"The access token provided is
invalid"}{'\n'}
{'{'}"error":"token_expired","error_description":"The access token provided has
expired"}{'\n'}
{'{'}"result":"error","error":"invalid_input","error_description":"The input JSON
payload is invalid"}{'\n'}
{'{'}"result":"error","error":"username_exists_already","error_description":"The
specified username exists already"}{'\n'}
{'{'}"result":"error","error":"no_such_customer",{'\n'}"error_description":"The specified
customer username or customer_address does not exist"}{'\n'}
For Example:{'\n'}
ERROR RESPONSES:{'\n'}
{'{'}{'\n'}
"type":"user",{'\n'}
"name":"testuser",{'\n'}
"username":"testuser@contentether.com",{'\n'}
"customer_username":"testadmin@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"status":"active",{'\n'}
"result":"error",{'\n'}
"error":"username_exists_already",{'\n'}
"error_description":"The specified username exists already"
}
</pre>




<ScrollableAnchor id="section06"> 
  <div>
    <br/>
    <Header as='h3'>6. Transfer CEToken(s) to User from Customer</Header>
  </div>
</ScrollableAnchor>
<Divider/>
     


<pre>


Request:{'\n'}
POST /api/transferToUser{'\n'}
HTTP Header - oAuth 2.0 token ( [Authorization] => Bearer
7cddf943078f158e6130d90aedd06856395aa6e0 ){'\n'}
Content-Type: application/json{'\n'}
Body:{'\n'}
{'{'}{'\n'}
"type": "user",{'\n'}
"name":"testuser7",{'\n'}
"username":"testuser7@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"network":"Rinkeby Test Network",{'\n'}
"CEToken":"0.000000001",{'\n'}
"customer_username":"testadmin7@contentether.com"{'\n'}
}{'\n'}
Responses:{'\n'}
{'{'}{'\n'}
"type":"user",{'\n'}
"name":"testuser7",{'\n'}
"username":"testuser7@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"network":"Rinkeby Test Network",{'\n'}
"CEToken":"0.000000001",{'\n'}
"customer_username":"testadmin7@contentether.com",{'\n'}
"result":"success",{'\n'}
"transactionID":"0xd3c3fcab2b987cb7a17f45b5455989c027de5cac5d8b
b534230b71213f34c253"{'\n'}
}{'\n'}
To see the transaction related information use the following link:{'\n'}
https://rinkeby.etherscan.io/tx/{'<'}TRANSACTION_ID_FROM_RESPONSE >{'\n'}
For Example:{'\n'}
https://rinkeby.etherscan.io/tx/0xd3c3fcab2b987cb7a17f45b5455989c027de5
cac5d8bb534230b71213f34c253{'\n'}
ERROR OUTPUT{'\n'}
The following errors can come as output response.{'\n'}
{'{'}"error":"invalid_token","error_description":"The access token provided is
invalid"}{'\n'}

{'{'}"error":"token_expired","error_description":"The access token provided has
expired"}{'\n'}
{'{'}"result":"error","error":"invalid_input","error_description":"The input JSON
payload is invalid"}{'\n'}
{'{'}"result":"error","error":"insufficient_balance","error_description":"Account
balance is insufficient."}{'\n'}
{'{'} "result":"error","error":"account_error ",{'\n'}"error_description":"Account is
disabled or temporarily suspended. Please contact customer care for further
assistance."}{'\n'}
{'{'}"result":"error","error":"no_such_customer",{'\n'}"error_description":"The specified
username does not exist"}{'\n'}
{'{'}"result":"error","error":"transaction_error",{'\n'}"error_description":"Insufficient
balance for the transaction"}{'\n'}
{'{'}"type":"user","name":"testuser","username":"testuser@contentether.com","api_
version":"1",{'\n'}"network":"Rinkeby Test
Network","CEToken":"0.000000001","result":"error","error":"transaction_error",{'\n'}
"error_description":"Failed to retrieve ERC20 token decimals\/scale value"}{'\n'}
{'{'}"type":"user","name":"testuser","username":"testuser@contentether.com","api_
version":"1",{'\n'}"network":"Rinkeby Test
Network","CEToken":"0.000000001",{'\n'}"customer_username":"testadmin@content
ether.com","result":"error","error":"transaction_error",{'\n'}"error_description":"insuf
ficient funds for gas * price + value"}{'\n'}
{'{'}"result":"error","error":"invalid_network",{'\n'}"error_description":"The network
specified is invalid"}{'\n'}{'\n'}
List of Valid Networks:{'\n'}{'\n'}
• Main Ethereum Network: Ethereum public main network{'\n'}
• Rinkeby Test Network: Rinkeby, the public Geth PoA testnet{'\n'}
• Private Ethereum Network: ContentEther, Ethereum private network{'\n'}
•
0: Olympic, Ethereum public pre-release testnet{'\n'}
•
1: Frontier, Homestead, Metropolis, the Ethereum public main network{'\n'}
•
1: Classic, the (un)forked public Ethereum Classic main network, chain ID 61{'\n'}
•
1: Expanse, an alternative Ethereum implementation, chain ID 2{'\n'}
•
2: Morden, the public Ethereum testnet, now Ethereum Classic testnet{'\n'}
•
3: Ropsten, the public cross-client Ethereum testnet{'\n'}
•
4: Rinkeby, the public Geth PoA testnet{'\n'}
•
5: Guerli, the public Geth/Parity PoA testnet{'\n'}
•
8: Ubiq, the public Gubiq main network with flux difficulty chain ID 8{'\n'}
•
42: Kovan, the public Parity PoA testnet{'\n'}
•
77: Sokol, the public POA Network testnet{'\n'}
•
99: Core, the public POA Network main network{'\n'}

•
100: xDai, the public MakerDAO/POA Network main network{'\n'}
•
401697: Tobalaba, the public Energy Web Foundation testnet{'\n'}
•
7762959: Musicoin, the music blockchain{'\n'}
•
61717561: Aquachain, ASIC resistant chain{'\n'}
•
[Other]: Could indicate that your connected to a local development test network.{'\n'}

</pre>




<ScrollableAnchor id="section07"> 
  <div>
    <br/>
    <Header as='h3'>7. Get User CEToken(s) Balance</Header>
  </div>
</ScrollableAnchor>
<Divider/>
     




<pre>

{'\n'}
Request:{'\n'}
POST /api/getUserBalance{'\n'}
HTTP Header - oAuth 2.0 token ( [Authorization] => Bearer
7cddf943078f158e6130d90aedd06856395aa6e0 ){'\n'}
Content-Type: application/json{'\n'}
Body:{'\n'}
{'{'}{'\n'}
"type": "user",{'\n'}
"name":"testuser7",{'\n'}
"username":"testuser7@contentether.com",{'\n'}
"customer_username":"testadmin7@contentether.com",{'\n'}
"api_version":"1"{'\n'}
}{'\n'}{'\n'}
Responses:{'\n'}{'\n'}
{'{'}{'\n'}
"type":"user",{'\n'}
"name":"testuser7",{'\n'}
"username":"testuser7@contentether.com",{'\n'}
"customer_username":"testadmin7@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"result":"success",{'\n'}
"credentials":{'{'}{'\n'}
"Main Ethereum Network":{'{'}
"address":"0x9a5f048d47c3747ec14d8cb082419da6aebc423e",{'\n'}
"CEToken":"0x0",{'\n'}
"USD":"0.00",{'\n'}
"ETH":"0.000",{'\n'}
"status":"active"},{'\n'}
"Rinkeby Test Network":{'{'}{'\n'}
"address":"0x4b5378fd24885eb20e9bffc42c676f7a395ed0c1",{'\n'}
"CEToken":"0.000000001",{'\n'}
"USD":"0.00",{'\n'}
"ETH":"0x1c6bf52634000",{'\n'}
"status":"active"},{'\n'}
"Private Ethereum Network":{'{'}{'\n'}
"address":"0xc1246085e201940eddd70965472d8277e18ca575",{'\n'}
"CEToken":"0x0",{'\n'}
"USD":"0.00",{'\n'}
"ETH":"0.000",{'\n'}
"status":"active"}{'\n'}
}{'\n'}
}{'\n'}
{'\n'}
ERROR OUTPUT{'\n'}

The following errors can come as output response.{'\n'}
{'{'}"error":"invalid_token","error_description":"The access token provided is
invalid"}{'\n'}
{'{'}"error":"token_expired","error_description":"The access token provided has
expired"}{'\n'}
{'{'} "result":"error","error":"invalid_input","error_description":"The input JSON
payload is invalid"}{'\n'}
{'{'}"result":"error","error":"no_such_user","error_description":"The specified
username does not exist "}{'\n'}
{'\n'}
</pre>



<ScrollableAnchor id="section08"> 
  <div>
    <br/>
    
    <Header as='h3'>8. Redeem User CEToken(s) to Customer</Header>
  </div>
</ScrollableAnchor>
<Divider/>
     



<pre>
 
Request:{'\n'}
POST /api/redeem{'\n'}
HTTP Header - oAuth 2.0 token ( [Authorization] => Bearer
7cddf943078f158e6130d90aedd06856395aa6e0 ){'\n'}
Content-Type: application/json{'\n'}
Body:{'\n'}
{'{'}
"type": "user",{'\n'}
"name":"testuser7",{'\n'}
"username":"testuser7@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"network":"Rinkeby Test Network",{'\n'}
"CEToken":"0.000000001",{'\n'}
"customer_username":"testadmin7@contentether.com"{'\n'}
}{'\n'}
Responses:{'\n'}
{'{'}{'\n'}
"type":"user",{'\n'}
"name":"testuser7",{'\n'}
"username":"testuser7@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"network":"Rinkeby Test Network",{'\n'}
"CEToken":"0.000000001",{'\n'}
"customer_username":"testadmin7@contentether.com",{'\n'}
"result":"success",{'\n'}
"transactionID":"0x438b507c7e390b81e5686f5c460d01e6e47ea2b9bb6f
0b618cb2d64cee65363a"{'\n'}
}{'\n'}{'\n'}
To see the transaction related information use the following link:{'\n'}
https://rinkeby.etherscan.io/tx/{'<'}TRANSACTION_ID_FROM_RESPONSE >{'\n'}
For Example:{'\n'}
https://rinkeby.etherscan.io/tx/0xd3c3fcab2b987cb7a17f45b5455989c027de5
cac5d8bb534230b71213f34c253{'\n'}{'\n'}
ERROR OUTPUT{'\n'}
{'\n'}
The following errors can come as output response.{'\n'}

{'{'}"error":"invalid_token","error_description":"The access token provided is
invalid"}{'\n'}
{'{'}"error":"token_expired","error_description":"The access token provided has
expired"}{'\n'}
{'{'}"result":"error","error":"invalid_input","error_description":"The input JSON
payload is invalid"}{'\n'}
{'{'}"result":"error","error":"insufficient_balance","error_description":"Account
balance is insufficient."}{'\n'}
{'{'} "result":"error","error":"account_error ",{'\n'}"error_description":"Account is
disabled or temporarily suspended. Please contact customer care for further
assistance."}{'\n'}
{'{'}"result":"error","error":"no_such_customer",{'\n'}"error_description":"The specified
username does not exist"}{'\n'}
{'{'}"result":"error","error":"transaction_error","error_description":"Insufficient
balance for the transaction"}{'\n'}
{'{'}"type":"user","name":"testuser","username":"testuser@contentether.com","api_
version":"1",{'\n'}"network":"Rinkeby Test
Network","CEToken":"0.000000001","result":"error","error":"transaction_error",{'\n'}
"error_description":"Failed to retrieve ERC20 token decimals\/scale value"}{'\n'}
{'{'}"type":"user","name":"testuser","username":"testuser@contentether.com","api_
version":"1",{'\n'}"network":"Rinkeby Test
Network","CEToken":"0.000000001","customer_username":"testadmin@content
ether.com","result":{'\n'}"error","error":"transaction_error",{'\n'}"error_description":"insuf
ficient funds for gas * price + value"}{'\n'}
{'{'}"result":"error","error":"invalid_network",{'\n'}"error_description":"The network
specified is invalid"}{'\n'}
List of Valid Networks:{'\n'}
• Main Ethereum Network: Ethereum public main network{'\n'}
• Rinkeby Test Network: Rinkeby, the public Geth PoA testnet{'\n'}
• Private Ethereum Network: ContentEther, Ethereum private network{'\n'}
•
0: Olympic, Ethereum public pre-release testnet{'\n'}
•
1: Frontier, Homestead, Metropolis, the Ethereum public main network{'\n'}
•
1: Classic, the (un)forked public Ethereum Classic main network, chain ID 61{'\n'}
•
1: Expanse, an alternative Ethereum implementation, chain ID 2{'\n'}
•
2: Morden, the public Ethereum testnet, now Ethereum Classic testnet{'\n'}
•
3: Ropsten, the public cross-client Ethereum testnet{'\n'}
•
4: Rinkeby, the public Geth PoA testnet{'\n'}
•
5: Guerli, the public Geth/Parity PoA testnet{'\n'}
•
8: Ubiq, the public Gubiq main network with flux difficulty chain ID 8{'\n'}
•
42: Kovan, the public Parity PoA testnet{'\n'}

•
77: Sokol, the public POA Network testnet{'\n'}
•
99: Core, the public POA Network main network{'\n'}
100: xDai, the public MakerDAO/POA Network main network{'\n'}
•
401697: Tobalaba, the public Energy Web Foundation testnet{'\n'}
•
7762959: Musicoin, the music blockchain{'\n'}
•
61717561: Aquachain, ASIC resistant chain{'\n'}
•
[Other]: Could indicate that your connected to a local development test network.{'\n'}

</pre>



<ScrollableAnchor id="section09"> 
  <div>
    <br/>
    <Header as='h3'> 9. Transfer CEToken(s) from User1 to User2</Header>
  </div>
</ScrollableAnchor>
<Divider/>
     




<pre>

Request:{'\n'}
POST /api/userTokenTranfser{'\n'}
HTTP Header - oAuth 2.0 token ( [Authorization] => Bearer
7cddf943078f158e6130d90aedd06856395aa6e0 ){'\n'}
Content-Type: application/json{'\n'}
Body:{'\n'}
{'{'}{'\n'}
"type": "user",{'\n'}
"from_name":"testuser7",{'\n'}
"from_username":"testuser7@contentether.com",
"from_customer_username":"testadmin7@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"network":"Rinkeby Test Network",{'\n'}
"CEToken":"0.000000001",{'\n'}
"to_name":"testuser8",{'\n'}
"to_username":"testuser8@contentether.com",{'\n'}
"to_customer_username":"testadmin7@contentether.com"{'\n'}
}{'\n'}{'\n'}
Responses:{'\n'}
{'\n'}
{'{'}{'\n'}
"type":"user",{'\n'}
"from_name":"testuser7",{'\n'}
"from_username":"testuser7@contentether.com",{'\n'}
"from_customer_username":"testadmin7@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"network":"Rinkeby Test Network",{'\n'}
"CEToken":"0.000000001",{'\n'}
"to_name":"testuser8",{'\n'}
"to_username":"testuser8@contentether.com",{'\n'}
"to_customer_username":"testadmin7@contentether.com",{'\n'}
"result":"success",{'\n'}
"transactionID":"0x706e0d77422aacd04feee1ca7efa7cf1d0fb3c985866c
491e44f00b40521e070"{'\n'}
}{'\n'}{'\n'}
To see the transaction related information use the following link:{'\n'}
https://rinkeby.etherscan.io/tx/{'<'}TRANSACTION_ID_FROM_RESPONSE >{'\n'}
For Example:{'\n'}
https://rinkeby.etherscan.io/tx/0x706e0d77422aacd04feee1ca7efa7cf1d0fb3c9
85866c491e44f00b40521e070{'\n'}
{'\n'}
ERROR OUTPUT{'\n'}{'\n'}
The following errors can come as output response.{'\n'}
{'{'}"error":"invalid_token","error_description":"The access token provided is
invalid"}{'\n'}
{'{'}"error":"token_expired",{'\n'}"error_description":"The access token provided has
expired"}{'\n'}
{'{'}"result":"error","error":"invalid_input",{'\n'}"error_description":"The input JSON
payload is invalid"}{'\n'}
{'{'}"result":"error","error":"insufficient_balance","error_description":"Account
balance is insufficient."}{'\n'}
{'{'} "result":"error","error":"account_error ",{'\n'}"error_description":"Account is
disabled or temporarily suspended. Please contact customer care for further
assistance."}{'\n'}
{'{'}"result":"error","error":"no_such_customer",{'\n'}"error_description":"The specified
username does not exist"}{'\n'}
{'{'}"result":"error","error":"transaction_error","error_description":"Insufficient
balance for the transaction"}{'\n'}
{'{'}"type":"user","name":"testuser","username":"testuser@contentether.com","api_
version":"1",{'\n'}"network":"Rinkeby Test
Network","CEToken":"0.000000001","result":"error","error":"transaction_error",{'\n'}
"error_description":"Failed to retrieve ERC20 token decimals\/scale value"}{'\n'}
{'{'}"type":"user","name":"testuser","username":"testuser@contentether.com","api_
version":"1",{'\n'}"network":"Rinkeby Test
Network","CEToken":"0.000000001","customer_username":"testadmin@content
ether.com",{'\n'}"result":"error","error":"transaction_error",{'\n'}"error_description":"insuf
ficient funds for gas * price + value"}{'\n'}
{'{'}"result":"error","error":"invalid_network",{'\n'}"error_description":"The network
specified is invalid"}{'\n'}
List of Valid Networks:{'\n'}
• Main Ethereum Network: Ethereum public main network{'\n'}
• Rinkeby Test Network: Rinkeby, the public Geth PoA testnet{'\n'}
• Private Ethereum Network: ContentEther, Ethereum private network{'\n'}
•
0: Olympic, Ethereum public pre-release testnet{'\n'}
•
1: Frontier, Homestead, Metropolis, the Ethereum public main network{'\n'}
•
1: Classic, the (un)forked public Ethereum Classic main network, chain ID 61{'\n'}
•
1: Expanse, an alternative Ethereum implementation, chain ID 2{'\n'}
•
2: Morden, the public Ethereum testnet, now Ethereum Classic testnet{'\n'}
•
3: Ropsten, the public cross-client Ethereum testnet{'\n'}

•
4: Rinkeby, the public Geth PoA testnet{'\n'}
•
5: Guerli, the public Geth/Parity PoA testnet{'\n'}
•
8: Ubiq, the public Gubiq main network with flux difficulty chain ID 8{'\n'}
•
42: Kovan, the public Parity PoA testnet{'\n'}
•
77: Sokol, the public POA Network testnet{'\n'}
•
99: Core, the public POA Network main network{'\n'}
•
100: xDai, the public MakerDAO/POA Network main network{'\n'}
•
401697: Tobalaba, the public Energy Web Foundation testnet{'\n'}
•
7762959: Musicoin, the music blockchain{'\n'}
•
61717561: Aquachain, ASIC resistant chain{'\n'}
•
[Other]: Could indicate that your connected to a local development test network.{'\n'}
</pre>


<ScrollableAnchor id="section10"> 
  <div>
    <br/>
    <Header as='h3'> 10) Activate/De-activate a Customer</Header>
  </div>
</ScrollableAnchor>
<Divider/>
     




<pre>


Request:{'\n'}
POST /api/manage_customer{'\n'}
HTTP Header - oAuth 2.0 token ( [Authorization] => Bearer
7cddf943078f158e6130d90aedd06856395aa6e0 ){'\n'}
Content-Type: application/json{'\n'}
Body:{'\n'}
{'{'}{'\n'}
"type": "customer",{'\n'}
"name":"testadmin",{'\n'}
"username":"testadmin@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"status":"inactive"{'\n'}
}{'\n'}{'\n'}
Response output:{'\n'}
SUCCESS OUTPUT{'\n'}
{'{'}{'\n'}
"type":"customer",{'\n'}
"name":"testadmin",{'\n'}
"username":"testadmin@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"status":"inactive",{'\n'}
"result":"success",{'\n'}
"credentials":{'{'}{'\n'}
"Main Ethereum Network":{'{'}{'\n'}
"address":"0x69178b49086aeb23f28c03fce9e7fb75787dfc39",{'\n'}
"CEToken":"0x0",{'\n'}
"USD":"0.00",{'\n'}
"ETH":"0.000",{'\n'}
"status":"inactive"},{'\n'}
"Rinkeby Test Network":{'{'}{'\n'}
"address":"0xe793dc066e9b43f59f229ab9b4385cad5dd3fa27",{'\n'}
"CEToken":"0x0",{'\n'}
"USD":"0.00",{'\n'}
"ETH":"0.000",{'\n'}
"status":"inactive"},{'\n'}
"Private Ethereum Network":{'{'}{'\n'}
"address":"0x16ff620cc941d1ef7892606850495e2101f70d76",{'\n'}
"CEToken":"0x0",{'\n'}
"USD":"0.00",{'\n'}
"ETH":"0.000",{'\n'}
"status":"inactive"}{'\n'}
}{'\n'}
}{'\n'}
{'\n'}
ERROR OUTPUT{'\n'}{'\n'}
The following errors can come as output response.{'\n'}{'\n'}
{'{'}"error":"invalid_token","error_description":"The access token provided is
invalid"}{'\n'}
{'{'}"error":"token_expired","error_description":"The access token provided has
expired"}{'\n'}
{'{'}"result":"error","error":"invalid_input","error_description":"The input JSON
payload is invalid"}{'\n'}
{'{'}"result":"error","error":"no_such_customer","error_description":"The specified
username does not exist or suspended"}{'\n'}
{'\n'}

</pre>



<ScrollableAnchor id="section11"> 
  <div>
    <br/>
    <Header as='h3'> 11) Activate/De-activate a User</Header>
  </div>
</ScrollableAnchor>
<Divider/>
     




<pre>

Request:{'\n'}
POST /api/manage_user{'\n'}
HTTP Header - oAuth 2.0 token ( [Authorization] => Bearer
7cddf943078f158e6130d90aedd06856395aa6e0 ){'\n'}
Content-Type: application/json{'\n'}
Body:{'\n'}
{'{'}{'\n'}
"type": "user",{'\n'}
"name":"testuser",{'\n'}
"username":"testuser@contentether.com",{'\n'}
"customer_username":"testadmin@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"status":"inactive"{'\n'}
}{'\n'}{'\n'}

Response output:{'\n'}
SUCCESS OUTPUT{'\n'}
{'{'}{'\n'}
"type":"user",{'\n'}
"name":"testuser",{'\n'}
"username":"testuser@contentether.com",{'\n'}
"customer_username":"testadmin@contentether.com",{'\n'}
"api_version":"1",{'\n'}
"status":"inactive",{'\n'}
"result":"success",{'\n'}
"credentials":{'{'}{'\n'}
"Main Ethereum Network":{'{'}{'\n'}
"address":"0x1af407327e2bd0ce3a68849ef4a18ec8220ba7b2",{'\n'}
"CEToken":"0x0",{'\n'}
"USD":"0.00",{'\n'}
"ETH":"0.000",{'\n'}
"status":"inactive"},{'\n'}
"Rinkeby Test Network":{'{'}{'\n'}
"address":"0x188eaa9067e6e4bb0771c60ed29dc1065799ee30",{'\n'}
"CEToken":"0x0",{'\n'}
"USD":"0.00",{'\n'}
"ETH":"0.000",{'\n'}
"status":"inactive"},{'\n'}
"Private Ethereum Network":{'{'}{'\n'}
"address":"0x3a28017178d5982e6975a4d8320dcab0ff3b9b43",{'\n'}
"CEToken":"0x0",{'\n'}
"USD":"0.00",{'\n'}
"ETH":"0.000",{'\n'}
"status":"inactive"}{'\n'}
{'\n'}
}{'\n'}
}{'\n'}
ERROR OUTPUT{'\n'}
The following errors can come as output response.{'\n'}
{'{'}"error":"invalid_token","error_description":"The access token provided is
invalid"}{'\n'}
{'{'}"error":"token_expired","error_description":"The access token provided has
expired"}{'\n'}
{'{'}"result":"error","error":"invalid_input","error_description":"The input JSON
payload is invalid"}{'\n'}
{'{'}"result":"error","error":"no_such_user","error_description":"The specified
username does not exist or suspended"}{'\n'}

</pre>



<ScrollableAnchor id="section12"> 
  <div>
    <br/>
    <Header as='h3'>12) Get Transactions of a Customer</Header>
  </div>
</ScrollableAnchor>
<Divider/>
     




<pre>
{'\n'}
Request:{'\n'}
POST /api/getCustomerTransactions{'\n'}
HTTP Header - oAuth 2.0 token ( [Authorization] => Bearer
7cddf943078f158e6130d90aedd06856395aa6e0 ){'\n'}
Content-Type: application/json{'\n'}
Body:{'\n'}
{'{'}{'\n'}
"type": "customer",{'\n'}
"name":"testadmin7",{'\n'}
"username":"testadmin7@contentether.com",{'\n'}
"network":"Rinkeby Test Network",{'\n'}
"api_version":"1",{'\n'}
"count":"10"{'\n'}
}{'\n'}
{'\n'}
Response output:{'\n'}
SUCCESS OUTPUT{'\n'}
{'{'}{'\n'}
"type":"customer",{'\n'}
"name":"testadmin7",{'\n'}
"username":"testadmin7@contentether.com",{'\n'}
"network":"Rinkeby Test Network",{'\n'}
"api_version":"1",{'\n'}
"count":"10",{'\n'}
"result":"success",{'\n'}
"transactions":[{'{'}{'\n'}
"time":"1552673092",{'\n'}
"sender":"0x9bab2ff1108667542489278144dd92ae50e2ce17",{'\n'}
"receiver":"0xfb7458abc523d6694c96e5b75d341a28a88fbb24",{'\n'}
"sender_transaction":"0x8637665496e7fc5d1b4cea77b1ec1b924e
6577bd010d3a2fd317dae389ac696d",{'\n'}
"send_amount":"Sent 0.000000001 CEToken",{'\n'}
"receiver_transaction":"0x8637665496e7fc5d1b4cea77b1ec1b92
4e6577bd010d3a2fd317dae389ac696d",{'\n'}
"receiver_amount":"Received 0.000000001 CEToken"}]{'\n'}
}{'\n'}{'\n'}

ERROR OUTPUT{'\n'}{'\n'}
The following errors can come as output response.{'\n'}
{'{'}"error":"invalid_token","error_description":"The access token provided is
invalid"}{'\n'}

{'{'}"error":"token_expired","error_description":"The access token provided has
expired"}{'\n'}
{'{'}"result":"error","error":"invalid_input","error_description":"The input JSON
payload is invalid"}{'\n'}
{'{'}"result":"error","error":"no_such_customer","error_description":"The specified
username does not exist or suspended"}{'\n'}



</pre>



<ScrollableAnchor id="section13"> 
  <div>
    <br/>
    <Header as='h3'> 13) Get Transactions of an User</Header>
  </div>
</ScrollableAnchor>
<Divider/>
     





<pre>
{'\n'}
Request:{'\n'}
POST /api/getUserTransactions{'\n'}
HTTP Header - oAuth 2.0 token ( [Authorization] => Bearer
7cddf943078f158e6130d90aedd06856395aa6e0 ){'\n'}
Content-Type: application/json{'\n'}
Body:{'\n'}
{'{'}{'\n'}
"type": "user",{'\n'}
"name":"testuser9",{'\n'}
"username":"testuser9@contentether.com",{'\n'}
"customer_username":"testadmin7@contentether.com",{'\n'}
"network":"Rinkeby Test Network",{'\n'}
"api_version":"1",{'\n'}
"count":"10"{'\n'}
}{'\n'}

Response output:{'\n'}
SUCCESS OUTPUT{'\n'}
{'{'}{'\n'}
"type":"user",{'\n'}
"name":"testuser9",{'\n'}
"username":"testuser9@contentether.com",{'\n'}
"customer_username":"testadmin7@contentether.com",{'\n'}
"network":"Rinkeby Test Network",{'\n'}
"api_version":"1",{'\n'}
"count":"10",{'\n'}
"result":"success",{'\n'}
"transactions":[{'\n'}
{'{'}"time":"1552673092",{'\n'}
"sender":"0x9bab2ff1108667542489278144dd92ae50e2ce17",{'\n'}
"receiver":"0xfb7458abc523d6694c96e5b75d341a28a88fbb24",{'\n'}
"sender_transaction":"0x8637665496e7fc5d1b4cea77b1ec1b924e
6577bd010d3a2fd317dae389ac696d",{'\n'}
"send_amount":"Sent 0.000000001 CEToken",{'\n'}
"receiver_transaction":"0x8637665496e7fc5d1b4cea77b1ec1b92
4e6577bd010d3a2fd317dae389ac696d",{'\n'}
"receiver_amount":"Received 0.000000001 CEToken"},{'\n'}
{'{'}"time":"1552673171",{'\n'}
"sender":"0xfb7458abc523d6694c96e5b75d341a28a88fbb24",{'\n'}
"receiver":"0xfb7458abc523d6694c96e5b75d341a28a88fbb24",{'\n'}
"sender_transaction":"0x1a8e171c1a9186f1ab6ca96fd44620b3ec
0aae067e14a559403c1648e7e10068",{'\n'}
"send_amount":"Transfer 0.000000001 CEToken",{'\n'}

"receiver_transaction":"0x1a8e171c1a9186f1ab6ca96fd44620b3e
c0aae067e14a559403c1648e7e10068",{'\n'}
"receiver_amount":"Received 0.000000001 CEToken"},{'\n'}
{'{'}"time":"1552673171",{'\n'}
"sender":"0xfb7458abc523d6694c96e5b75d341a28a88fbb24",{'\n'}
"receiver":"0xfb7458abc523d6694c96e5b75d341a28a88fbb24",{'\n'}
"sender_transaction":"0x1a8e171c1a9186f1ab6ca96fd44620b3ec
0aae067e14a559403c1648e7e10068",{'\n'}
"send_amount":"Transfer 0.000000001 CEToken",{'\n'}
"receiver_transaction":"0x1a8e171c1a9186f1ab6ca96fd44620b3e
c0aae067e14a559403c1648e7e10068",{'\n'}
"receiver_amount":"Received 0.000000001 CEToken"}]{'\n'}
}{'\n'}
{'\n'}
ERROR OUTPUT{'\n'}
The following errors can come as output response.{'\n'}
{'{'}"error":"invalid_token","error_description":"The access token provided is
invalid"}{'\n'}
{'{'}"error":"token_expired","error_description":"The access token provided has
expired"}{'\n'}
{'{'}"result":"error","error":"invalid_input","error_description":"The input JSON
payload is invalid"}{'\n'}
{'{'}"result":"error","error":"no_such_user","error_description":"The specified
username does not exist or suspended"}{'\n'}
</pre>

      </Container>
    </Segment>
  </div>
)

export default APIDocs