import {GET_MEMBER,GET_MEMBERS,MEMBER_LOADING,GET_MEMBER_BALANCE,REDEEM_USER_TO_CUSTOMER} from '../actions/types'

const initialState={
  member: null,
  members:null,
  redeem:null,
  loading:false
}

export default function(state=initialState,action){
  switch(action.type){
    case MEMBER_LOADING:
      return{
        ...state,
        loading:true
      }
    case GET_MEMBER:
      return {
        ...state,
        member:action.payload,
        loading:false
      }
    case GET_MEMBERS:
    return {
      ...state,
      members:action.payload,
      loading:false
     
    }    
    case GET_MEMBER_BALANCE:
    return {
      ...state,
      member:action.payload,
      loading:false
     
    }
    case REDEEM_USER_TO_CUSTOMER:
      return {
        ...state,
        redeem:action.payload,
        loading:false
      }

    default:
      return state
  }
}