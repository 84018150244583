import React,{Fragment} from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
const TextFieldGroup = ({
  name,
  placeholder,
  value,
  label,
  error,
  info,
  type,
  icon,
  onChange,
  disabled
}) => {
  console.log(error)
  return (
    <Fragment >
        {error &&  <Message color='red'>{error}</Message>  }
        {info && <small className="form-text text-muted" style={{textAlign:"left"}}>{info}</small>}
      {icon ?
      <Form.Input fluid
        type={type}
        className={classnames("", {
          "is-invalid": error
        })}
        icon ={icon}
        iconPosition='left'
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />: <Form.Input fluid
      type={type}
      className={classnames("", {
        "is-invalid": error
      })}
 
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />}

     
  
    </Fragment>
  );
};

TextFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
TextFieldGroup.defaultProps = {
  type: "text"
};
export default TextFieldGroup;
