import React, { PureComponent } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

const data = [
  {
    name: 'Jan ', uv: 4 , pv: 4, amt: 10,
  },
  {
    name: 'Feb', uv: 3 , pv: 3, amt: 10,
  },
  {
    name: 'Mar', uv: 2 , pv: 2, amt: 10,
  },
  {
    name: 'Apr', uv: 2 , pv: 2, amt: 10,
  },
  {
    name: 'May', uv: 1 , pv: 1, amt: 10,
  },
  {
    name: 'Jun', uv: 3, pv: 3, amt: 10,
  },
  {
    name: 'Jul', uv:4, pv: 4, amt: 10,
  },
];

export default class Example extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/xqjtetw0/';

  render() {
    return (
      <LineChart
        width={500}
        height={200}
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
      </LineChart>
    );
  }
}
