import React from "react";

export default () => {
  return (
    <div className="ml-3">
      <h3 className="display-4">Page Not Found</h3>
      <p>Sorry, this page does not exist</p>
    </div>
  );
};
