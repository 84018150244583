import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Container,
   Grid,
  Header,
  Image,
  Segment,
 } from 'semantic-ui-react'

 
import { HashLink as Link } from 'react-router-hash-link';

class Landing extends Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  render() {
    return (
      <Container fluid>
      <Segment style={{ padding: '6em 0em 2em 0em' }} vertical>
      
        <Grid container stackable verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column width={6}>
              <Header as='h2' style={{ fontSize: '2.5em' }}>
              Blockchain for Loyalty
              </Header>
              <p style={{ fontSize: '1.33em' }}>
              Built for media companies, brands and retailers to reward their loyal guests with tokens. ContentEther works with existing loyalty and payment programs to tokenize them into the blockchain.
              </p>
              <Link className="ui violet basic button" to="/#stamping"  scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'end' })}>Stamping & Verification</Link>
              <Link className="ui violet basic button" to="/#loyalty"  scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'end' })}>Loyalty</Link>
         
            
            </Grid.Column>
            <Grid.Column   width={10}>
              <Image   src='/img/crypto.jpg' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{backgroundColor:"rgb(242, 245, 247)", padding:"20px 0"}}>
            <Grid.Column width={16} textAlign="center">
              <Header as='h2' style={{ fontSize: '2.2em' }}>
              How it works
              </Header>
              </Grid.Column>
          </Grid.Row>
  
          <Grid.Row style={{backgroundColor:"rgb(242, 245, 247)"}}>
            <Grid.Column width={4} textAlign="center"> 
              <div className="bubble_element center">
               <Image  centered   size="mini"   src='/img/setting-3.svg' className="imgCenter" /> 
              </div>
              <Header as='h3' style={{ fontSize: '1.5em' }}>
              Setup an Account
              </Header>
              <p style={{ fontSize: '1.33em' }}>
              Signup with our sales team setup an account and obtain an API key.
              </p><br/><br/>
            </Grid.Column>
            <Grid.Column width={4} textAlign="center"> 
              <div className="bubble_element center">
               <Image  centered   size="mini"   src='/img/setting-4.svg' className="imgCenter" /> 
              </div>
              <Header as='h3' style={{ fontSize: '1.5em' }}>
              Connect API
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                Connect our API to your loyalty systems.
              </p><br/><br/>
            </Grid.Column>
  
            <Grid.Column width={4} textAlign="center"> 
              <div className="bubble_element center">
               <Image  centered   size="mini"   src='/img/value-2.svg' className="imgCenter" /> 
              </div>
              <Header as='h3' style={{ fontSize: '1.5em' }}>
              Obtain Tokens
              </Header>
              <p style={{ fontSize: '1.33em' }}>
             
              Your guests will receive tokens based on your loyalty program rules.
              </p><br/><br/>
            </Grid.Column>
  
            <Grid.Column width={4} textAlign="center"> 
              <div className="bubble_element center">
               <Image  centered   size="mini"   src='/img/network.svg' className="imgCenter" /> 
              </div>
              <Header as='h3' style={{ fontSize: '1.5em' }}>
              Monitor and Predict
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                Our dashboard will report loyalty habits and predictions.
              </p><br/><br/>
            </Grid.Column>
          </Grid.Row>



          <Grid.Row  style={{ padding: '40px 0 0 0' }}  >
            <Grid.Column width={16} textAlign="center">
              <Header as='h2' style={{ fontSize: '2.2em' }}>
             Industries
              </Header>
              </Grid.Column>
          </Grid.Row>


          <Grid.Row  >
          <Grid.Column width={4} textAlign="center" style={{backgroundColor:"rgb(100, 53, 201)",color:"white"}} > 
            <br/>
              <div className="  center">
               <Image  centered   size="small"   src='/img/media.png' className="imgCenter" /> 
              </div>
              <Header as='h3' style={{ fontSize: '1.5em',color:"white" }}>
              Media
              </Header>
              <p style={{ fontSize: '1.33em' }}>
              Media companies can now integrate with the blockchain to receive payments, reward their loyal users, create gamification based on points and stamp/verify content authenticity.
              </p><br/>
            </Grid.Column>
           
  
            <Grid.Column width={4} textAlign="center" style={{backgroundColor:"rgb(100, 53, 201)",color:"white"}} > 
            <br/>
              <div className="  center">
               <Image  centered   size="small"   src='/img/research.png' className="imgCenter" /> 
              </div>
              <Header as='h3' style={{ fontSize: '1.5em' ,color:"white"}}>
              Research
              </Header>
              <p style={{ fontSize: '1.33em' }}>
             
              Timestamping of research materials on the public blockchains allows the researchers to take credit work. Any later plagiarism attempt can easily be proven and discounted by the blockchain.
            <br/>
              </p><br/>
            </Grid.Column>    
            <Grid.Column width={4} textAlign="center" style={{backgroundColor:" rgb(100, 53, 201)",color:"white"}} >  
            <br/>
              <div className="  center">
               <Image  centered   size="small" src='/img/law.png' className="imgCenter" /> 
              </div>
              <Header as='h3' style={{ fontSize: '1.5em' ,color:"white"}}>
             Law
              </Header>
              <p style={{ fontSize: '1.33em' }}>
              Lawyers can verify signatures in contracts and other legal documents. Blockchain timestamping offers an additional layer of trustless immutable verification of the exact time when these were verified.
              </p><br/>
            </Grid.Column>
           
          </Grid.Row>


        

          <Grid.Row   id="stamping" style={{ padding: '40px 0 0 0' }}  >
            
            <Grid.Column width={7} textAlign="center"   >  
            <br/>
              <div className="  center">
               <Image  centered   size="small" src='/img/stamping.png' className="imgCenter" /> 
              </div>
              <Header as='h4' style={{ fontSize: '1.5em'}}>
             Stamping on the Blockchain
              </Header>
              <p style={{ fontSize: '1.33em' }}>
              Stamping of documents/content can allow consumers to verify the issue history of the article. Storing on the blockchain prevents potential malicious amendment at a later stage.
Blockchain stamping allows a public verification of the document’s date, authenticity, origin, integrity & content without publicly exposing the content itself.
              </p><br/>
            </Grid.Column>
  
           
  
            <Grid.Column width={7} textAlign="center"  > 
        
              <div className="  center">
               <Image  centered   size="small"   src='/img/verify.png' className="imgCenter" /> 
              </div>
              <Header as='h4' style={{ fontSize: '1.5em'  }}>
              Verifying from the Chain

              </Header>
              <p style={{ fontSize: '1.33em' }}>
              The immutable blockchain timestamp can become proof of prior art existence of authenticity by others in the future for the same or similar content. If you have a transaction ID from the block chain you can verify here.
              </p><br/><br/><br/>
            </Grid.Column>
          </Grid.Row>


                    
          <Grid.Row  style={{backgroundColor:"rgb(242, 245, 247)", padding:"20px 0"}}>
            <Grid.Column width={16} textAlign="center">
              <Header as='h3' style={{ fontSize: '1.8em' }}>
              "Our Ethereum based CEToken blockchain provides maximum flexibility to use public as well as private blockchain systems. Our engines have the ability to analyze and predict behaviors based on the blockchain data."
              </Header>
              </Grid.Column>
          </Grid.Row>
  
          <Grid.Row style={{  padding:"40px 0"}}>
            <Grid.Column width={16} textAlign="center">
              <Header as='h3' style={{ fontSize: '1.2em' }}>
              Speak to our Sales Team to learn more Contact Sales
              </Header>
              <Button size="big"      color='violet'>Contact Sales</Button>
              </Grid.Column>
          </Grid.Row>

 

          <Grid.Row style={{  padding:"20px 0"}}>
            <Grid.Column width={16} textAlign="center">
                <div className="  center">
               <Image  centered   size="tiny"   src='/img/contentether_01.png' className="imgCenter" /> 
              </div>
            </Grid.Column>
            <Grid.Column width={16} textAlign="center">
              <div>
                <Button circular color='facebook' icon='facebook' />
                <Button circular color='twitter' icon='twitter' />
                <Button circular color='linkedin' icon='linkedin' />
                
              </div>
            </Grid.Column>
            <Grid.Column width={16} textAlign="center"  id="loyalty" >
              <div>
              Pacific Capital Plaza 4F<br/>
              4-8-4 Roppongi, Minato-ku<br/>
              Tokyo 106-0032, Japan<br/>
                
              </div>
            </Grid.Column>

          </Grid.Row>

        </Grid>
   
      </Segment>
   
   
      </Container> 
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(Landing);
