import {GET_CUSTOMER,GET_CUSTOMERS,CUSTOMER_LOADING,TRANSFER_TO_CUSTOMER} from '../actions/types'

const initialState={
  customer: null,
  customers:null,
  loading:false
}

export default function(state=initialState,action){
  switch(action.type){
    case CUSTOMER_LOADING:
      return{
        ...state,
        loading:true
      }
    case GET_CUSTOMER:
      return {
        ...state,
        customer:action.payload,
        loading:false
      }
    case GET_CUSTOMERS:
    return {
      ...state,
      customers:action.payload,
      loading:false
     
    }
    case TRANSFER_TO_CUSTOMER:
    return {
      ...state,
      loading:false
    }
    default:
      return state
  }
}