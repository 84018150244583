import React, { Component } from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import formatCurrency from 'format-currency'
export default class CustomCell extends Component {
  render() {
    const credentials = this.props.dataItem.credentials;


    const lineItem=Object.keys(credentials).map((key,idx)=>{
    //  console.log(key,credentials[key])
      const network=key
      const address=credentials[key].address
      const CEToken="CEToken: "+formatCurrency(credentials[key].CEToken*1000000000) 
      const ETH="ETH: "+credentials[key].ETH
      const USD="USD: "+credentials[key].USD
      const isShow=(key==="Rinkeby Test Network")?{display:'block'}:{display:'none'};
      const link="https://rinkeby.etherscan.io/address/"+address
      return ( 
       <Segment.Group  key={idx} style={isShow}>
         
        <Segment  style={{color:"blue"}}><a href={link} target="_blank"><b>{network}</b><br/>{address}</a></Segment>
        <Segment  style={{color:"grey"}}><span  style={{color:"red"}}>{CEToken}</span></Segment>
        
      </Segment.Group>
       
      
       )
      }
    )
 

        return (
            <td  > 
              {lineItem} 
            </td>
             );
  }
}
