import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';


const data = [
  {
    name: 'Jan ', uv: 4 , user:1, amt: 10,
  },
  {
    name: 'Feb', uv: 3 , user: 2, amt: 10,
  },
  {
    name: 'Mar', uv: 2 , user: 4, amt: 10,
  },
  {
    name: 'Apr', uv: 2 , user: 6, amt: 10,
  },
  {
    name: 'May', uv: 1 , user: 8, amt: 10,
  },
  {
    name: 'Jun', uv: 3, user: 10, amt: 10,
  },
  {
    name: 'Jul', uv:4, user: 22, amt: 10,
  },
];

export default class Example extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/9hjfkp73/';

  render() {
    return (
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="user" stackId="a" fill="#8884d8" />
        <Bar dataKey="amt" stackId="a" fill="#82ca9d" />
      
      </BarChart>
    );
  }
}
