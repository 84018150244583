export const GET_USERS = "GET_USERS";
export const GET_ERRORS = "GET_ERRORS";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const GET_PROFILES = "GET_PROFILES";

export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const CUSTOMER_LOADING = "CUSTOMER_LOADING";
export const TRANSFER_TO_CUSTOMER = "TRANSFER_TO_CUSTOMER";
export const GET_CUSTOMER_BALANCE = "GET_CUSTOMER_BALANCE";

 
export const GET_MEMBER = "GET_MEMBER";
export const GET_MEMBERS  = "GET_MEMBERS";
export const MEMBER_LOADING = "MEMBER_LOADING";
export const TRANSFER_TO_MEMBER = "TRANSFER_TO_MEMBER";
export const GET_MEMBER_BALANCE = "GET_MEMBER_BALANCE";
export const REDEEM_USER_TO_CUSTOMER="REDEEM_USER_TO_CUSTOMER"