import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { GET_ERRORS, SET_CURRENT_USER,GET_USERS } from "./types";



// Get all users
export const getUsers = () => dispatch => {
  axios
    .get("/api/users")
    .then(res =>
      dispatch({
        type: GET_USERS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_USERS,
        payload: {}
      })
    );
};


// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post("/api/users/register", userData)
    .then(res => history.push("/login"))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Register User by Iterate Salesman
export const activateUser = (userData, history) => dispatch => {
  axios
    .post("/api/users/register", userData)
    .then(res => {
      dispatch(createProfile(userData));
      dispatch(sendActivationEmail(userData));
      history.push({
        pathname: "/customer-activation-success",
        state: { detail: userData }
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Set logged in user
export const createProfile = userData => dispatch => {
  const profileData = {
    handle: userData.name.replace(/ /g, "_"),
    company: userData.companyname
  };
  axios
    .post("/api/profile", profileData)
    .then(res => console.log("profile created"))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Send activation email to  user
export const sendActivationEmail = userData => dispatch => {
  /*
  companyname: "Central"
email: "chris@central.com"
name: "Chris Walter"
packagetype: "1"
password: "123456"
password2: "123456"
payment: "1"
  */
  const { name, companyname, email, packagetype, payment } = userData;
  const data = {
    name,
    companyname,
    email,
    packagetype,
    payment,
    recipients: email
  };
  axios
    .post("/api/inquiry/activate", data)
    .then(res => console.log("activation email sent"))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Login - Get User Token
export const loginUser = userData => dispatch => {
  axios
    .post("/api/users/login", userData)
    .then(res => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to local storage
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      dispatch(getApiToken())
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};



// Login - Get User Token
export const getApiToken =()=> dispatch => {
  axios
    .post("/api/users/gettoken")
    .then(res => {
      // Save to localStorage
      const { access_token } = res.data;
      // Set token to local storage
      localStorage.setItem("access_token", access_token);
      
     
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
